import Vue from 'vue'
import Router from 'vue-router'
import * as scrollFunctions from '~/mixins/scroll.functions.js'
import UserPage from '~/pages/user_page/_.vue'
import AuthorList from '~/pages/author_list/_.vue'
import ArticleList from '~/pages/article_list/_.vue'
import ArticlePag from '~/pages/article_page/_.vue'
import GalleryPag from '~/pages/gallery_page/_.vue'
import Recommender from '~/pages/recommender/_.vue'
import ReviewList from '~/pages/review_list/_.vue'
import BrandList from '~/pages/brand_list/_.vue'
import StorePage from '~/pages/store_page/_.vue'
import RaceList from '~/pages/race_list/_.vue'
import RacePage from '~/pages/race_page/_.vue'
import InquiryPage from '~/pages/inquiry_page/_.vue'
import TalkPage from '~/pages/talk_page/_.vue'
import ProductList from '~/pages/product_list/_.vue'
import TagPage from '~/pages/tag_page/_.vue'
import ComparatorPag from '~/pages/comparator_page/_.vue'
import ComparisonPag from '~/pages/comparison_page/_.vue'
import InquiryList from '~/pages/inquiry_list/_.vue'
import OpinionList from '~/pages/opinion_list/_.vue'
import PriceList from '~/pages/price_list/_.vue'
import ProductPrices from '~/pages/product_prices/_.vue'
import ProductPag from '~/pages/product_page/_.vue'
import DiscountCodesList from '~/pages/discount_codes_list/_.vue'
import Calculator from '~/pages/calculator/_.vue'
import CalculatorMarathon from '~/pages/calculator_marathon/_.vue'
import CalculatorHalfMarathon from '~/pages/calculator_half_marathon/_.vue'
import Calculator10Km from '~/pages/calculator_10_km/_.vue'
import NotFoundPage from '~/pages/not_found_page/_.vue'
import RedirectingPag from '~/pages/redirecting/_.vue'
import AudiosList from '~/pages/audios_list/_.vue'
import AudiosPage from '~/pages/audios_page/_.vue'
import AuthLogin from '~/pages/auth_login/_.vue'
import AuthRedirect from '~/pages/auth_redirect/_.vue'
import AuthCallback from '~/pages/auth_callback/_.vue'
import AuthData from '~/pages/auth_data/_.vue'
import AuthNewsletter from '~/pages/auth_newsletter/_.vue'
import AuthRemember from '~/pages/auth_remember/_.vue'
import AuthPassword from '~/pages/auth_password/_.vue'
import AuthSignin from '~/pages/auth_signin/_.vue'
import CountriesRedirect from '~/pages/countries_redirect/_.vue'
import LandingPag from '~/pages/landing_page/_.vue'

Vue.use(Router)

export function createRouter () {
  return new Router({
    mode: 'history',
    base: '/',
    scrollBehavior: (to, from) => {
      scrollFunctions.init()

      if (to.hash) {
        scrollFunctions.set(to.hash)
        return false
      }

      return { x: 0, y: 0 }
    },
    routes: [
      {
        path:'/utilizador/',
        component:UserPage
      },
      {
        path:'/artigos/autores/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"articles"}
      },
      {
        path:'/artigos/autores/:autor/:pag(\\d+)?',
        component:ArticleList,
        props:{"breadcrumb":[{"text":"autores","href":"/artigos/autores/"}]}
      },
      {
        path:'/artigos/categorias/:categoria/:pag(\\d+)?',
        component:ArticleList,
        props:{"breadcrumb":[{"text":"Articulos","href":"/artigos/"}]}
      },
      {
        path:'/artigos/etiquetas/:etiqueta/:pag(\\d+)?',
        component:ArticleList,
        props:{"article":"etiqueta","filter":"tag","breadcrumb":[{"text":"etiqueta","href":"/artigos/etiquetas/"}]}
      },
      {
        path:'/artigos/:pag(\\d+)?',
        component:ArticleList,
        props:{"breadcrumb":[{"text":"Articulos","href":"/artigos/"}]}
      },
      {
        path:'/artigos/*index.amp.html',
        component:ArticlePag,
        meta:{"amp":true}
      },
      {
        path:'/artigos/*',
        component:ArticlePag
      },
      {
        path:'/galerias/*.amp.html',
        component:GalleryPag,
        meta:{"amp":true}
      },
      {
        path:'/galerias/*.html',
        component:GalleryPag
      },
      {
        path:'/shoe-finder/index.amp.html',
        component:Recommender,
        meta:{"amp":true}
      },
      {
        path:'/shoe-finder/',
        component:Recommender
      },
      {
        path:'/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/reviews/provadores/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/autores/:pag(\\d+)?',
        component:AuthorList
      },
      {
        path:'/marcas/',
        component:BrandList
      },
      {
        path:'/store/:tienda/*',
        component:StorePage
      },
      {
        path:'/corridas-populares/calendario/:pathMatch?',
        component:RaceList,
        props:{"breadcrumb":[{"text":"Corridas populares","href":"/corridas-populares/calendario/"}]}
      },
      {
        path:'/corridas-populares/calendario/*',
        component:RaceList,
        props:{"breadcrumb":[{"text":"Corridas populares","href":"/corridas-populares/calendario/"}]}
      },
      {
        path:'/corridas-populares/*/inscricoes.html',
        component:RacePage,
        props:{"tipo":"inscriptions","breadcrumb":[{"text":"Corridas populares","href":"/corridas-populares/calendario/"}]}
      },
      {
        path:'/corridas-populares/*/percurso.html',
        component:RacePage,
        props:{"tipo":"route","breadcrumb":[{"text":"Corridas populares","href":"/corridas-populares/calendario/"}]}
      },
      {
        path:'/corridas-populares/*/verificar.html',
        component:RacePage,
        props:{"tipo":"verify","breadcrumb":[{"text":"Corridas populares","href":"/corridas-populares/calendario/"}]}
      },
      {
        path:'/corridas-populares/*/forum.html',
        component:RacePage,
        props:{"tipo":"forum","breadcrumb":[{"text":"Corridas populares","href":"/corridas-populares/calendario/"}]}
      },
      {
        path:'/corridas-populares/*',
        component:RacePage,
        props:{"tipo":"","breadcrumb":[{"text":"Corridas populares","href":"/corridas-populares/calendario/"}]}
      },
      {
        path:'/*/questoes/*-:id.html',
        component:InquiryPage,
        props:{"prodTypeId":"1","breadcrumb":[{"text":"/Questões","href":"/sapatilhas-running/questoes/todas/"}]}
      },
      {
        path:'/comentarios/articulos/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comentarios/articulos/"}]}
      },
      {
        path:'/buscador/modelos/todos/*/:q?',
        component:ProductList,
        props:{"prodTypeId":"0","breadcrumb":[{"text":"modelos","href":"/buscador/modelos/todos/"}]}
      },
      {
        path:'/buscador/modelos/:marca/*/:q?',
        component:ProductList,
        props:{"prodTypeId":"0","breadcrumb":[{"text":"modelos","href":"/buscador/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"1,50,62,64,84"}
      },
      {
        path:'/sapatilhas/comparador/',
        component:ComparatorPag,
        props:{"prodTypeId":"1,50,62,64,84"}
      },
      {
        path:'/sapatilhas/comparativo/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"1,50,62,64,84"}
      },
      {
        path:'/sapatilhas/questoes/recomendacoes/*',
        component:InquiryList,
        props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Sapatilhas","href":"/sapatilhas/modelos/todos/"}],"Type":1}
      },
      {
        path:'/comentarios/sapatilhas/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comentarios/sapatilhas/"}]}
      },
      {
        path:'/sapatilhas/reviews/provadores/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"1,50,62,64,84"}
      },
      {
        path:'/sapatilhas/reviews/provadores/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/sapatilhas/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/sapatilhas/opinioes/todos/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Opiniones","href":"/opinioes/todos/"}]}
      },
      {
        path:'/sapatilhas/modelos/todos/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Sapatilhas","href":"/sapatilhas/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas/modelos/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Sapatilhas","href":"/sapatilhas/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas/outlet/todos/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Sapatilhas","href":"/sapatilhas/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Sapatilhas","href":"/sapatilhas/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas/*/amp/precos/todos/*',
        component:ProductPrices,
        props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Sapatilhas","href":"/sapatilhas/modelos/todos/"}]},
        meta:{"amp":true}
      },
      {
        path:'/sapatilhas/*/precos/todos/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Sapatilhas","href":"/sapatilhas/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas/*/amp/precos/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Sapatilhas","href":"/sapatilhas/modelos/todos/"}]},
        meta:{"amp":true}
      },
      {
        path:'/sapatilhas/*/precos/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"1,50,62,64,84","breadcrumb":[{"text":"Sapatilhas","href":"/sapatilhas/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"1,50,62,64,84"},
        meta:{"amp":true}
      },
      {
        path:'/sapatilhas/*',
        component:ProductPag,
        props:{"prodTypeId":"1,50,62,64,84"}
      },
      {
        path:'/sapatilhas-running/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"1"}
      },
      {
        path:'/sapatilhas-running/comparador/',
        component:ComparatorPag,
        props:{"prodTypeId":"1"}
      },
      {
        path:'/sapatilhas-running/comparativo/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"1"}
      },
      {
        path:'/sapatilhas-running/questoes/recomendacoes/*',
        component:InquiryList,
        props:{"prodTypeId":"1","breadcrumb":[{"text":"Sapatilhas running","href":"/sapatilhas-running/modelos/todos/"}],"Type":1}
      },
      {
        path:'/comentarios/sapatilhas-running/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comentarios/sapatilhas-running/"}]}
      },
      {
        path:'/sapatilhas-running/reviews/provadores/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"1"}
      },
      {
        path:'/sapatilhas-running/reviews/provadores/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"1","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/sapatilhas-running/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"1","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/sapatilhas-running/opinioes/todos/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"1","breadcrumb":[{"text":"Opiniones","href":"/opinioes/todos/"}]}
      },
      {
        path:'/sapatilhas-running/modelos/todos/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"1","breadcrumb":[{"text":"Sapatilhas running","href":"/sapatilhas-running/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-running/modelos/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"1","breadcrumb":[{"text":"Sapatilhas running","href":"/sapatilhas-running/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-running/outlet/todos/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"1","breadcrumb":[{"text":"Sapatilhas running","href":"/sapatilhas-running/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-running/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"1","breadcrumb":[{"text":"Sapatilhas running","href":"/sapatilhas-running/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-running/*/amp/precos/todos/*',
        component:ProductPrices,
        props:{"prodTypeId":"1","breadcrumb":[{"text":"Sapatilhas running","href":"/sapatilhas-running/modelos/todos/"}]},
        meta:{"amp":true}
      },
      {
        path:'/sapatilhas-running/*/precos/todos/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"1","breadcrumb":[{"text":"Sapatilhas running","href":"/sapatilhas-running/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-running/*/amp/precos/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"1","breadcrumb":[{"text":"Sapatilhas running","href":"/sapatilhas-running/modelos/todos/"}]},
        meta:{"amp":true}
      },
      {
        path:'/sapatilhas-running/*/precos/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"1","breadcrumb":[{"text":"Sapatilhas running","href":"/sapatilhas-running/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-running/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"1"},
        meta:{"amp":true}
      },
      {
        path:'/sapatilhas-running/*',
        component:ProductPag,
        props:{"prodTypeId":"1"}
      },
      {
        path:'/relogios-desportivos/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"45"}
      },
      {
        path:'/relogios-desportivos/comparador/',
        component:ComparatorPag,
        props:{"prodTypeId":"45"}
      },
      {
        path:'/relogios-desportivos/comparativo/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"45"}
      },
      {
        path:'/relogios-desportivos/questoes/recomendacoes/*',
        component:InquiryList,
        props:{"prodTypeId":"45","breadcrumb":[{"text":"Relógios desportivos","href":"/relogios-desportivos/modelos/todos/"}],"Type":1}
      },
      {
        path:'/comentarios/relogios-desportivos/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comentarios/relogios-desportivos/"}]}
      },
      {
        path:'/relogios-desportivos/reviews/provadores/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"45"}
      },
      {
        path:'/relogios-desportivos/reviews/provadores/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"45","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/relogios-desportivos/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"45","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/relogios-desportivos/opinioes/todos/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"45","breadcrumb":[{"text":"Opiniones","href":"/opinioes/todos/"}]}
      },
      {
        path:'/relogios-desportivos/modelos/todos/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"45","breadcrumb":[{"text":"Relógios desportivos","href":"/relogios-desportivos/modelos/todos/"}]}
      },
      {
        path:'/relogios-desportivos/modelos/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"45","breadcrumb":[{"text":"Relógios desportivos","href":"/relogios-desportivos/modelos/todos/"}]}
      },
      {
        path:'/relogios-desportivos/outlet/todos/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"45","breadcrumb":[{"text":"Relógios desportivos","href":"/relogios-desportivos/modelos/todos/"}]}
      },
      {
        path:'/relogios-desportivos/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"45","breadcrumb":[{"text":"Relógios desportivos","href":"/relogios-desportivos/modelos/todos/"}]}
      },
      {
        path:'/relogios-desportivos/*/amp/precos/todos/*',
        component:ProductPrices,
        props:{"prodTypeId":"45","breadcrumb":[{"text":"Relógios desportivos","href":"/relogios-desportivos/modelos/todos/"}]},
        meta:{"amp":true}
      },
      {
        path:'/relogios-desportivos/*/precos/todos/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"45","breadcrumb":[{"text":"Relógios desportivos","href":"/relogios-desportivos/modelos/todos/"}]}
      },
      {
        path:'/relogios-desportivos/*/amp/precos/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"45","breadcrumb":[{"text":"Relógios desportivos","href":"/relogios-desportivos/modelos/todos/"}]},
        meta:{"amp":true}
      },
      {
        path:'/relogios-desportivos/*/precos/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"45","breadcrumb":[{"text":"Relógios desportivos","href":"/relogios-desportivos/modelos/todos/"}]}
      },
      {
        path:'/relogios-desportivos/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"45"},
        meta:{"amp":true}
      },
      {
        path:'/relogios-desportivos/*',
        component:ProductPag,
        props:{"prodTypeId":"45"}
      },
      {
        path:'/sneakers/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"50"}
      },
      {
        path:'/sneakers/comparador/',
        component:ComparatorPag,
        props:{"prodTypeId":"50"}
      },
      {
        path:'/sneakers/comparativo/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"50"}
      },
      {
        path:'/sneakers/questoes/recomendacoes/*',
        component:InquiryList,
        props:{"prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modelos/todos/"}],"Type":1}
      },
      {
        path:'/comentarios/sneakers/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comentarios/sneakers/"}]}
      },
      {
        path:'/sneakers/reviews/provadores/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"50"}
      },
      {
        path:'/sneakers/reviews/provadores/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"50","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/sneakers/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"50","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/sneakers/opinioes/todos/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"50","breadcrumb":[{"text":"Opiniones","href":"/opinioes/todos/"}]}
      },
      {
        path:'/sneakers/modelos/todos/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modelos/todos/"}]}
      },
      {
        path:'/sneakers/modelos/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modelos/todos/"}]}
      },
      {
        path:'/sneakers/outlet/todos/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modelos/todos/"}]}
      },
      {
        path:'/sneakers/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modelos/todos/"}]}
      },
      {
        path:'/sneakers/*/amp/precos/todos/*',
        component:ProductPrices,
        props:{"prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modelos/todos/"}]},
        meta:{"amp":true}
      },
      {
        path:'/sneakers/*/precos/todos/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modelos/todos/"}]}
      },
      {
        path:'/sneakers/*/amp/precos/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modelos/todos/"}]},
        meta:{"amp":true}
      },
      {
        path:'/sneakers/*/precos/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"50","breadcrumb":[{"text":"Sneakers","href":"/sneakers/modelos/todos/"}]}
      },
      {
        path:'/sneakers/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"50"},
        meta:{"amp":true}
      },
      {
        path:'/sneakers/*',
        component:ProductPag,
        props:{"prodTypeId":"50"}
      },
      {
        path:'/sapatilhas-fitness/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"62"}
      },
      {
        path:'/sapatilhas-fitness/comparador/',
        component:ComparatorPag,
        props:{"prodTypeId":"62"}
      },
      {
        path:'/sapatilhas-fitness/comparativo/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"62"}
      },
      {
        path:'/sapatilhas-fitness/questoes/recomendacoes/*',
        component:InquiryList,
        props:{"prodTypeId":"62","breadcrumb":[{"text":"Sapatilhas de fitness","href":"/sapatilhas-fitness/modelos/todos/"}],"Type":1}
      },
      {
        path:'/comentarios/sapatilhas-fitness/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comentarios/sapatilhas-fitness/"}]}
      },
      {
        path:'/sapatilhas-fitness/reviews/provadores/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"62"}
      },
      {
        path:'/sapatilhas-fitness/reviews/provadores/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"62","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/sapatilhas-fitness/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"62","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/sapatilhas-fitness/opinioes/todos/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"62","breadcrumb":[{"text":"Opiniones","href":"/opinioes/todos/"}]}
      },
      {
        path:'/sapatilhas-fitness/modelos/todos/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"62","breadcrumb":[{"text":"Sapatilhas de fitness","href":"/sapatilhas-fitness/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-fitness/modelos/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"62","breadcrumb":[{"text":"Sapatilhas de fitness","href":"/sapatilhas-fitness/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-fitness/outlet/todos/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"62","breadcrumb":[{"text":"Sapatilhas de fitness","href":"/sapatilhas-fitness/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-fitness/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"62","breadcrumb":[{"text":"Sapatilhas de fitness","href":"/sapatilhas-fitness/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-fitness/*/amp/precos/todos/*',
        component:ProductPrices,
        props:{"prodTypeId":"62","breadcrumb":[{"text":"Sapatilhas de fitness","href":"/sapatilhas-fitness/modelos/todos/"}]},
        meta:{"amp":true}
      },
      {
        path:'/sapatilhas-fitness/*/precos/todos/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"62","breadcrumb":[{"text":"Sapatilhas de fitness","href":"/sapatilhas-fitness/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-fitness/*/amp/precos/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"62","breadcrumb":[{"text":"Sapatilhas de fitness","href":"/sapatilhas-fitness/modelos/todos/"}]},
        meta:{"amp":true}
      },
      {
        path:'/sapatilhas-fitness/*/precos/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"62","breadcrumb":[{"text":"Sapatilhas de fitness","href":"/sapatilhas-fitness/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-fitness/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"62"},
        meta:{"amp":true}
      },
      {
        path:'/sapatilhas-fitness/*',
        component:ProductPag,
        props:{"prodTypeId":"62"}
      },
      {
        path:'/sapatilhas-crossfit/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"64"}
      },
      {
        path:'/sapatilhas-crossfit/comparador/',
        component:ComparatorPag,
        props:{"prodTypeId":"64"}
      },
      {
        path:'/sapatilhas-crossfit/comparativo/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"64"}
      },
      {
        path:'/sapatilhas-crossfit/questoes/recomendacoes/*',
        component:InquiryList,
        props:{"prodTypeId":"64","breadcrumb":[{"text":"Sapatilhas crossfit","href":"/sapatilhas-crossfit/modelos/todos/"}],"Type":1}
      },
      {
        path:'/comentarios/sapatilhas-crossfit/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comentarios/sapatilhas-crossfit/"}]}
      },
      {
        path:'/sapatilhas-crossfit/reviews/provadores/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"64"}
      },
      {
        path:'/sapatilhas-crossfit/reviews/provadores/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"64","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/sapatilhas-crossfit/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"64","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/sapatilhas-crossfit/opinioes/todos/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"64","breadcrumb":[{"text":"Opiniones","href":"/opinioes/todos/"}]}
      },
      {
        path:'/sapatilhas-crossfit/modelos/todos/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"64","breadcrumb":[{"text":"Sapatilhas crossfit","href":"/sapatilhas-crossfit/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-crossfit/modelos/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"64","breadcrumb":[{"text":"Sapatilhas crossfit","href":"/sapatilhas-crossfit/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-crossfit/outlet/todos/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"64","breadcrumb":[{"text":"Sapatilhas crossfit","href":"/sapatilhas-crossfit/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-crossfit/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"64","breadcrumb":[{"text":"Sapatilhas crossfit","href":"/sapatilhas-crossfit/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-crossfit/*/amp/precos/todos/*',
        component:ProductPrices,
        props:{"prodTypeId":"64","breadcrumb":[{"text":"Sapatilhas crossfit","href":"/sapatilhas-crossfit/modelos/todos/"}]},
        meta:{"amp":true}
      },
      {
        path:'/sapatilhas-crossfit/*/precos/todos/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"64","breadcrumb":[{"text":"Sapatilhas crossfit","href":"/sapatilhas-crossfit/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-crossfit/*/amp/precos/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"64","breadcrumb":[{"text":"Sapatilhas crossfit","href":"/sapatilhas-crossfit/modelos/todos/"}]},
        meta:{"amp":true}
      },
      {
        path:'/sapatilhas-crossfit/*/precos/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"64","breadcrumb":[{"text":"Sapatilhas crossfit","href":"/sapatilhas-crossfit/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-crossfit/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"64"},
        meta:{"amp":true}
      },
      {
        path:'/sapatilhas-crossfit/*',
        component:ProductPag,
        props:{"prodTypeId":"64"}
      },
      {
        path:'/sapatilhas-spinning/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"66"}
      },
      {
        path:'/sapatilhas-spinning/comparador/',
        component:ComparatorPag,
        props:{"prodTypeId":"66"}
      },
      {
        path:'/sapatilhas-spinning/comparativo/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"66"}
      },
      {
        path:'/sapatilhas-spinning/questoes/recomendacoes/*',
        component:InquiryList,
        props:{"prodTypeId":"66","breadcrumb":[{"text":"Sapatilhas spinning","href":"/sapatilhas-spinning/modelos/todos/"}],"Type":1}
      },
      {
        path:'/comentarios/sapatilhas-spinning/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comentarios/sapatilhas-spinning/"}]}
      },
      {
        path:'/sapatilhas-spinning/reviews/provadores/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"66"}
      },
      {
        path:'/sapatilhas-spinning/reviews/provadores/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"66","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/sapatilhas-spinning/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"66","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/sapatilhas-spinning/opinioes/todos/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"66","breadcrumb":[{"text":"Opiniones","href":"/opinioes/todos/"}]}
      },
      {
        path:'/sapatilhas-spinning/modelos/todos/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"66","breadcrumb":[{"text":"Sapatilhas spinning","href":"/sapatilhas-spinning/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-spinning/modelos/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"66","breadcrumb":[{"text":"Sapatilhas spinning","href":"/sapatilhas-spinning/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-spinning/outlet/todos/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"66","breadcrumb":[{"text":"Sapatilhas spinning","href":"/sapatilhas-spinning/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-spinning/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"66","breadcrumb":[{"text":"Sapatilhas spinning","href":"/sapatilhas-spinning/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-spinning/*/amp/precos/todos/*',
        component:ProductPrices,
        props:{"prodTypeId":"66","breadcrumb":[{"text":"Sapatilhas spinning","href":"/sapatilhas-spinning/modelos/todos/"}]},
        meta:{"amp":true}
      },
      {
        path:'/sapatilhas-spinning/*/precos/todos/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"66","breadcrumb":[{"text":"Sapatilhas spinning","href":"/sapatilhas-spinning/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-spinning/*/amp/precos/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"66","breadcrumb":[{"text":"Sapatilhas spinning","href":"/sapatilhas-spinning/modelos/todos/"}]},
        meta:{"amp":true}
      },
      {
        path:'/sapatilhas-spinning/*/precos/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"66","breadcrumb":[{"text":"Sapatilhas spinning","href":"/sapatilhas-spinning/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-spinning/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"66"},
        meta:{"amp":true}
      },
      {
        path:'/sapatilhas-spinning/*',
        component:ProductPag,
        props:{"prodTypeId":"66"}
      },
      {
        path:'/pulseiras-atividade/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"73"}
      },
      {
        path:'/pulseiras-atividade/comparador/',
        component:ComparatorPag,
        props:{"prodTypeId":"73"}
      },
      {
        path:'/pulseiras-atividade/comparativo/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"73"}
      },
      {
        path:'/pulseiras-atividade/questoes/recomendacoes/*',
        component:InquiryList,
        props:{"prodTypeId":"73","breadcrumb":[{"text":"Pulseiras de atividade","href":"/pulseiras-atividade/modelos/todos/"}],"Type":1}
      },
      {
        path:'/comentarios/pulseiras-atividade/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comentarios/pulseiras-atividade/"}]}
      },
      {
        path:'/pulseiras-atividade/reviews/provadores/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"73"}
      },
      {
        path:'/pulseiras-atividade/reviews/provadores/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"73","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/pulseiras-atividade/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"73","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/pulseiras-atividade/opinioes/todos/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"73","breadcrumb":[{"text":"Opiniones","href":"/opinioes/todos/"}]}
      },
      {
        path:'/pulseiras-atividade/modelos/todos/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"73","breadcrumb":[{"text":"Pulseiras de atividade","href":"/pulseiras-atividade/modelos/todos/"}]}
      },
      {
        path:'/pulseiras-atividade/modelos/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"73","breadcrumb":[{"text":"Pulseiras de atividade","href":"/pulseiras-atividade/modelos/todos/"}]}
      },
      {
        path:'/pulseiras-atividade/outlet/todos/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"73","breadcrumb":[{"text":"Pulseiras de atividade","href":"/pulseiras-atividade/modelos/todos/"}]}
      },
      {
        path:'/pulseiras-atividade/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"73","breadcrumb":[{"text":"Pulseiras de atividade","href":"/pulseiras-atividade/modelos/todos/"}]}
      },
      {
        path:'/pulseiras-atividade/*/amp/precos/todos/*',
        component:ProductPrices,
        props:{"prodTypeId":"73","breadcrumb":[{"text":"Pulseiras de atividade","href":"/pulseiras-atividade/modelos/todos/"}]},
        meta:{"amp":true}
      },
      {
        path:'/pulseiras-atividade/*/precos/todos/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"73","breadcrumb":[{"text":"Pulseiras de atividade","href":"/pulseiras-atividade/modelos/todos/"}]}
      },
      {
        path:'/pulseiras-atividade/*/amp/precos/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"73","breadcrumb":[{"text":"Pulseiras de atividade","href":"/pulseiras-atividade/modelos/todos/"}]},
        meta:{"amp":true}
      },
      {
        path:'/pulseiras-atividade/*/precos/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"73","breadcrumb":[{"text":"Pulseiras de atividade","href":"/pulseiras-atividade/modelos/todos/"}]}
      },
      {
        path:'/pulseiras-atividade/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"73"},
        meta:{"amp":true}
      },
      {
        path:'/pulseiras-atividade/*',
        component:ProductPag,
        props:{"prodTypeId":"73"}
      },
      {
        path:'/smartwatch/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"74"}
      },
      {
        path:'/smartwatch/comparador/',
        component:ComparatorPag,
        props:{"prodTypeId":"74"}
      },
      {
        path:'/smartwatch/comparativo/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"74"}
      },
      {
        path:'/smartwatch/questoes/recomendacoes/*',
        component:InquiryList,
        props:{"prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelos/todos/"}],"Type":1}
      },
      {
        path:'/comentarios/smartwatch/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comentarios/smartwatch/"}]}
      },
      {
        path:'/smartwatch/reviews/provadores/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"74"}
      },
      {
        path:'/smartwatch/reviews/provadores/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"74","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/smartwatch/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"74","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/smartwatch/opinioes/todos/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"74","breadcrumb":[{"text":"Opiniones","href":"/opinioes/todos/"}]}
      },
      {
        path:'/smartwatch/modelos/todos/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelos/todos/"}]}
      },
      {
        path:'/smartwatch/modelos/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelos/todos/"}]}
      },
      {
        path:'/smartwatch/outlet/todos/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelos/todos/"}]}
      },
      {
        path:'/smartwatch/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelos/todos/"}]}
      },
      {
        path:'/smartwatch/*/amp/precos/todos/*',
        component:ProductPrices,
        props:{"prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelos/todos/"}]},
        meta:{"amp":true}
      },
      {
        path:'/smartwatch/*/precos/todos/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelos/todos/"}]}
      },
      {
        path:'/smartwatch/*/amp/precos/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelos/todos/"}]},
        meta:{"amp":true}
      },
      {
        path:'/smartwatch/*/precos/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"74","breadcrumb":[{"text":"Smartwatch","href":"/smartwatch/modelos/todos/"}]}
      },
      {
        path:'/smartwatch/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"74"},
        meta:{"amp":true}
      },
      {
        path:'/smartwatch/*',
        component:ProductPag,
        props:{"prodTypeId":"74"}
      },
      {
        path:'/ciclocomputadores/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"75"}
      },
      {
        path:'/ciclocomputadores/comparador/',
        component:ComparatorPag,
        props:{"prodTypeId":"75"}
      },
      {
        path:'/ciclocomputadores/comparativo/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"75"}
      },
      {
        path:'/ciclocomputadores/questoes/recomendacoes/*',
        component:InquiryList,
        props:{"prodTypeId":"75","breadcrumb":[{"text":"Ciclocomputadores","href":"/ciclocomputadores/modelos/todos/"}],"Type":1}
      },
      {
        path:'/comentarios/ciclocomputadores/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comentarios/ciclocomputadores/"}]}
      },
      {
        path:'/ciclocomputadores/reviews/provadores/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"75"}
      },
      {
        path:'/ciclocomputadores/reviews/provadores/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"75","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/ciclocomputadores/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"75","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/ciclocomputadores/opinioes/todos/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"75","breadcrumb":[{"text":"Opiniones","href":"/opinioes/todos/"}]}
      },
      {
        path:'/ciclocomputadores/modelos/todos/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"75","breadcrumb":[{"text":"Ciclocomputadores","href":"/ciclocomputadores/modelos/todos/"}]}
      },
      {
        path:'/ciclocomputadores/modelos/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"75","breadcrumb":[{"text":"Ciclocomputadores","href":"/ciclocomputadores/modelos/todos/"}]}
      },
      {
        path:'/ciclocomputadores/outlet/todos/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"75","breadcrumb":[{"text":"Ciclocomputadores","href":"/ciclocomputadores/modelos/todos/"}]}
      },
      {
        path:'/ciclocomputadores/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"75","breadcrumb":[{"text":"Ciclocomputadores","href":"/ciclocomputadores/modelos/todos/"}]}
      },
      {
        path:'/ciclocomputadores/*/amp/precos/todos/*',
        component:ProductPrices,
        props:{"prodTypeId":"75","breadcrumb":[{"text":"Ciclocomputadores","href":"/ciclocomputadores/modelos/todos/"}]},
        meta:{"amp":true}
      },
      {
        path:'/ciclocomputadores/*/precos/todos/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"75","breadcrumb":[{"text":"Ciclocomputadores","href":"/ciclocomputadores/modelos/todos/"}]}
      },
      {
        path:'/ciclocomputadores/*/amp/precos/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"75","breadcrumb":[{"text":"Ciclocomputadores","href":"/ciclocomputadores/modelos/todos/"}]},
        meta:{"amp":true}
      },
      {
        path:'/ciclocomputadores/*/precos/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"75","breadcrumb":[{"text":"Ciclocomputadores","href":"/ciclocomputadores/modelos/todos/"}]}
      },
      {
        path:'/ciclocomputadores/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"75"},
        meta:{"amp":true}
      },
      {
        path:'/ciclocomputadores/*',
        component:ProductPag,
        props:{"prodTypeId":"75"}
      },
      {
        path:'/sapatilhas-trekking/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"84"}
      },
      {
        path:'/sapatilhas-trekking/comparador/',
        component:ComparatorPag,
        props:{"prodTypeId":"84"}
      },
      {
        path:'/sapatilhas-trekking/comparativo/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"84"}
      },
      {
        path:'/sapatilhas-trekking/questoes/recomendacoes/*',
        component:InquiryList,
        props:{"prodTypeId":"84","breadcrumb":[{"text":"Sapatilhas de trekking","href":"/sapatilhas-trekking/modelos/todos/"}],"Type":1}
      },
      {
        path:'/comentarios/sapatilhas-trekking/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comentarios/sapatilhas-trekking/"}]}
      },
      {
        path:'/sapatilhas-trekking/reviews/provadores/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"84"}
      },
      {
        path:'/sapatilhas-trekking/reviews/provadores/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"84","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/sapatilhas-trekking/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"84","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/sapatilhas-trekking/opinioes/todos/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"84","breadcrumb":[{"text":"Opiniones","href":"/opinioes/todos/"}]}
      },
      {
        path:'/sapatilhas-trekking/modelos/todos/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"84","breadcrumb":[{"text":"Sapatilhas de trekking","href":"/sapatilhas-trekking/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-trekking/modelos/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"84","breadcrumb":[{"text":"Sapatilhas de trekking","href":"/sapatilhas-trekking/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-trekking/outlet/todos/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"84","breadcrumb":[{"text":"Sapatilhas de trekking","href":"/sapatilhas-trekking/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-trekking/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"84","breadcrumb":[{"text":"Sapatilhas de trekking","href":"/sapatilhas-trekking/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-trekking/*/amp/precos/todos/*',
        component:ProductPrices,
        props:{"prodTypeId":"84","breadcrumb":[{"text":"Sapatilhas de trekking","href":"/sapatilhas-trekking/modelos/todos/"}]},
        meta:{"amp":true}
      },
      {
        path:'/sapatilhas-trekking/*/precos/todos/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"84","breadcrumb":[{"text":"Sapatilhas de trekking","href":"/sapatilhas-trekking/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-trekking/*/amp/precos/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"84","breadcrumb":[{"text":"Sapatilhas de trekking","href":"/sapatilhas-trekking/modelos/todos/"}]},
        meta:{"amp":true}
      },
      {
        path:'/sapatilhas-trekking/*/precos/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"84","breadcrumb":[{"text":"Sapatilhas de trekking","href":"/sapatilhas-trekking/modelos/todos/"}]}
      },
      {
        path:'/sapatilhas-trekking/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"84"},
        meta:{"amp":true}
      },
      {
        path:'/sapatilhas-trekking/*',
        component:ProductPag,
        props:{"prodTypeId":"84"}
      },
      {
        path:'/botas-montanha/*/tag/:pag(\\d+)?',
        component:TagPage,
        props:{"typeId":"1041"}
      },
      {
        path:'/botas-montanha/comparador/',
        component:ComparatorPag,
        props:{"prodTypeId":"1041"}
      },
      {
        path:'/botas-montanha/comparativo/:prd1/:prd2/*.html',
        component:ComparisonPag,
        props:{"prodTypeId":"1041"}
      },
      {
        path:'/botas-montanha/questoes/recomendacoes/*',
        component:InquiryList,
        props:{"prodTypeId":"1041","breadcrumb":[{"text":"Botas de montanha","href":"/botas-montanha/modelos/todos/"}],"Type":1}
      },
      {
        path:'/comentarios/botas-montanha/*.html',
        component:TalkPage,
        props:{"breadcrumb":[{"text":"Comentarios","href":"/comentarios/botas-montanha/"}]}
      },
      {
        path:'/botas-montanha/reviews/provadores/:pag(\\d+)?',
        component:AuthorList,
        props:{"filter":"reviews","prodTypeId":"1041"}
      },
      {
        path:'/botas-montanha/reviews/provadores/:autor/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"1041","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/botas-montanha/reviews/:pag(\\d+)?',
        component:ReviewList,
        props:{"prodTypeId":"1041","breadcrumb":[{"text":"Reviews","href":"/reviews/"}]}
      },
      {
        path:'/botas-montanha/opinioes/todos/:pag(\\d+)?',
        component:OpinionList,
        props:{"prodTypeId":"1041","breadcrumb":[{"text":"Opiniones","href":"/opinioes/todos/"}]}
      },
      {
        path:'/botas-montanha/modelos/todos/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"1041","breadcrumb":[{"text":"Botas de montanha","href":"/botas-montanha/modelos/todos/"}]}
      },
      {
        path:'/botas-montanha/modelos/:marca/*',
        component:ProductList,
        props:{"name":"ProductList","prodTypeId":"1041","breadcrumb":[{"text":"Botas de montanha","href":"/botas-montanha/modelos/todos/"}]}
      },
      {
        path:'/botas-montanha/outlet/todos/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"1041","breadcrumb":[{"text":"Botas de montanha","href":"/botas-montanha/modelos/todos/"}]}
      },
      {
        path:'/botas-montanha/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"1041","breadcrumb":[{"text":"Botas de montanha","href":"/botas-montanha/modelos/todos/"}]}
      },
      {
        path:'/botas-montanha/*/amp/precos/todos/*',
        component:ProductPrices,
        props:{"prodTypeId":"1041","breadcrumb":[{"text":"Botas de montanha","href":"/botas-montanha/modelos/todos/"}]},
        meta:{"amp":true}
      },
      {
        path:'/botas-montanha/*/precos/todos/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"1041","breadcrumb":[{"text":"Botas de montanha","href":"/botas-montanha/modelos/todos/"}]}
      },
      {
        path:'/botas-montanha/*/amp/precos/:tienda/*',
        component:ProductPrices,
        props:{"prodTypeId":"1041","breadcrumb":[{"text":"Botas de montanha","href":"/botas-montanha/modelos/todos/"}]},
        meta:{"amp":true}
      },
      {
        path:'/botas-montanha/*/precos/:tienda/*',
        component:ProductPrices,
        props:{"name":"ProductPrices","prodTypeId":"1041","breadcrumb":[{"text":"Botas de montanha","href":"/botas-montanha/modelos/todos/"}]}
      },
      {
        path:'/botas-montanha/*index.amp.html',
        component:ProductPag,
        props:{"prodTypeId":"1041"},
        meta:{"amp":true}
      },
      {
        path:'/botas-montanha/*',
        component:ProductPag,
        props:{"prodTypeId":"1041"}
      },
      {
        path:'/soutien-desportivo/outlet/todos/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"58","breadcrumb":[{"text":"Soutien desportivo","href":"/soutien-desportivo/outlet/todos/"}]}
      },
      {
        path:'/soutien-desportivo/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"58","breadcrumb":[{"text":"Soutien desportivo","href":"/soutien-desportivo/outlet/todos/"}]}
      },
      {
        path:'/leggings-running/outlet/todos/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"71","breadcrumb":[{"text":"Leggings running","href":"/leggings-running/outlet/todos/"}]}
      },
      {
        path:'/leggings-running/outlet/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"71","breadcrumb":[{"text":"Leggings running","href":"/leggings-running/outlet/todos/"}]}
      },
      {
        path:'/maquinas-fitness/todos/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"105","breadcrumb":[{"text":"Máquinas de fitness","href":"/maquinas-fitness/todos/"}]}
      },
      {
        path:'/maquinas-fitness/:tienda/*',
        component:PriceList,
        props:{"name":"PriceList","prodTypeId":"105","breadcrumb":[{"text":"Máquinas de fitness","href":"/maquinas-fitness/todos/"}]}
      },
      {
        path:'/cupoes-desconto/',
        component:DiscountCodesList
      },
      {
        path:'/calculadoras/calculadora-de-tempos/',
        component:Calculator
      },
      {
        path:'/calculadoras/calculadora-de-tempos/maratona/',
        component:CalculatorMarathon
      },
      {
        path:'/calculadoras/calculadora-de-tempos/meia-maratona/',
        component:CalculatorHalfMarathon
      },
      {
        path:'/calculadoras/calculadora-de-tempos/10-km/',
        component:Calculator10Km
      },
      {
        path:'/pagina-nao-encontrada',
        component:NotFoundPage
      },
      {
        path:'/404.html',
        component:NotFoundPage
      },
      {
        path:'/link/',
        component:RedirectingPag
      },
      {
        path:'/*/link/',
        component:RedirectingPag
      },
      {
        path:'/running-music/list',
        component:AudiosList
      },
      {
        path:'/running-music/playlist/:id',
        component:AudiosPage
      },
      {
        path:'/auth/login',
        component:AuthLogin
      },
      {
        path:'/auth/redirect',
        component:AuthRedirect
      },
      {
        path:'/auth/callback',
        component:AuthCallback
      },
      {
        path:'/auth/data',
        component:AuthData
      },
      {
        path:'/auth/newsletter',
        component:AuthNewsletter
      },
      {
        path:'/auth/remember',
        component:AuthRemember
      },
      {
        path:'/auth/password/:token',
        component:AuthPassword
      },
      {
        path:'/auth/signin',
        component:AuthSignin
      },
      {
        path:'/countries/:country',
        component:CountriesRedirect
      },
      {
        path:'/*',
        component:LandingPag
      }
    ]
  })
}
