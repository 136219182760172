export default {
  countries: {
    now: 'Você está em',
    change: 'Alterar',
    name: {
      ES: 'Espanha',
      FR: 'França',
      DE: 'Alemanha',
      IT: 'Itália',
      UK: 'Reino Unido',
      MX: 'México',
      PT: 'Portugal',
      EUR: 'Europa',
      AME: 'América'
    }
  },
  global: {
    timeAgoMinutes: 'há {minutes} min',
    timeAgoHours: 'há {hours} h',
    timeAgoHoursMinutes: 'há {hours} h e {minutes} min',
    timeAgoDay: 'há 1 dia',
    timeAgoDays: 'há {days} dias'
  },
  pages: {
    home: 'Início',
    leaf: 'Ficha',
    list: 'Lista de',
    contacts: 'Contactos'
  },
  components: {
    ProductDescription: {
      readMore: 'Ler mais'
    },
    SliderCardFinder: {
      most_find: 'O mais procurado:'
    }
  },
  product_types: {
    1: 'Sapatilhas de corrida',
    45: 'Relógios desportivos',
    50: 'Sneakers',
    62: 'Sapatilhas de fitness',
    64: 'Sapatilhas de crossfit',
    71: 'Roupa de corrida',
    72: 'Suplementos desportivos',
    73: 'Pulseiras de atividade',
    74: 'Smartwatch',
    75: 'Computadores de ciclismo',
    76: 'Auriculares desportivos',
    84: 'Sapatilhas de trekking',
    102: 'Óculos de sol',
    105: 'Máquinas de fitness'
  },
  sections: {
    4: 'Notícias de corrida',
    5: 'Treino de corrida',
    6: 'Nutrição',
    7: 'Lesões desportivas',
    15: 'O blogue Mizuno de Raquel',
    31: 'Atletas Skechers',
    32: 'Running Fashion Blogger',
    45: 'Podcast Runnea',
    58: 'Maratona de Nova Iorque',
    63: 'Mães corredoras',
    71: 'Outdoor',
    73: 'Equipa feminina Runnea',
    74: 'Eletrónica'
  },
  leaf: {
    features: {
      title: 'Características',
      list: {
        gender: 'Género',
        cushioning: 'Amortecimento',
        constitution: 'Constituição',
        footprint: 'Pisada',
        surface: 'Superfície',
        use: 'Uso',
        beat: 'Ritmo',
        foot: 'Pé',
        support: 'Apoio',
        distance: 'Distância',
        weight: 'Peso',
        weightf: 'Peso Mulher',
        weightm: 'Peso Homem',
        drop: 'Drop',
        droptie: 'Drop',
        colour: 'Cor',
        options: 'Opções',
        sensors: 'Sensores',
        connections: 'Conexões',
        autonomy: 'Autonomia (Modo GPS)',
        technology: 'Tecnologia',
        impermeability: 'impermeabilidade',
        sole: 'Sola',
        fabric: 'Tecido',
        sustainability: 'Sustentabilidade',
        year: 'Ano'
      },
      values: {
        48: 'Mais de 48 horas',
        man: 'Homem',
        woman: 'Mulher',
        low: 'Baixo',
        medium: 'Médio',
        high: 'Alto',
        short: 'Distâncias curtas',
        soft: 'Minimalista',
        track: 'Pista',
        light: 'Leve',
        strong: 'Forte',
        neutral: 'Neutro',
        supinator: 'Supinador',
        pronator: 'Pronador',
        asphalt: 'Asfalto',
        trail: 'Trail',
        training: 'Treino',
        competition: 'Competição',
        mixed: 'Vários',
        dig: 'Cavo',
        flat: 'Plano',
        normal: 'Normal',
        heel: 'Calcanhar',
        maraton: 'Maratona',
        'carbon-plate': 'Placa de carbono',
        'half-maraton': 'Meia maratona',
        '10k': '10K',
        fitness: 'Fitness',
        running: 'Running',
        running_shoes: 'Sapatilhas de corrida',
        cycling: 'Ciclismo',
        swimming: 'Natação/mergulho',
        outdoor: 'Outdoor',
        triathlon: 'Triatlo',
        crossfit: 'Crossfit',
        trekking: 'Trekking',
        gps: 'GPS',
        tactile: 'Ecrã tátil',
        color_screen: 'Ecrã a cores',
        maps: 'Mapas',
        pulse: 'Pulso',
        band: 'Cinta de peito',
        music: 'Música sem telemóvel',
        spotify: 'Spotify',
        strava: 'Strava',
        altimeter: 'Altímetro',
        thermometer: 'Termómetro',
        tensiometer: 'Medidor de tensão',
        oximeter: 'Oxímetro',
        barometer: 'Barómetro',
        bluetooth: 'Bluetooth',
        wifi: 'WI-FI',
        nfc: 'NFC',
        fm: 'Rádio FM',
        sim: 'Cartão SIM',
        '1-12': '1-12h',
        '13-24': '13-24h',
        '25-36': '25-36h',
        '37-48': '37-48h',
        yes: 'Sim',
        no: 'Não',
        'dry-line': 'Dry-line',
        'gore-tex': 'Gore-tex',
        'omni-tech': 'Omni-tech',
        outdry: 'Outdry',
        contagrip: 'Contagrip',
        continental: 'Continental',
        frixion: 'Frixion',
        michelin: 'Michelin',
        vibram: 'Vibram',
        tiptoe: 'Frente do pé',
        vegans: 'Veganas',
        'recycled-material': 'Material reciclado',
        co2: 'Sustentáveis em CO2'
      }
    },
    performances: {
      title: 'Prestações',
      lightness: 'Leveza',
      cushioning: 'Amortecimento',
      flexibility: 'Flexibilidade',
      responsive: 'Resposta',
      stability: 'Estabilidade',
      grip: 'Tração',
      durability: 'Durabilidade',
      fit: 'Ajuste',
      comfort: 'Comodidade',
      quality: 'Qualidade'
    }
  },
  list: {
    filters: {
      title: 'Filtros',
      brand: 'Marca',
      gender: 'Género',
      price: 'Preço',
      drop: 'Drop',
      droptie: 'Drop',
      cushioning: 'Amortecimento',
      constitution: 'Constituição',
      footprint: 'Pisada',
      surface: 'Superfície',
      use: 'Uso',
      beat: 'Ritmo',
      foot: 'Pé',
      support: 'Apoio',
      distance: 'Distância',
      sustainability: 'Sustentabilidade',
      size: 'Tamanhos',
      technology: 'Tecnologia',
      fabric: 'Tecido',
      store: 'Loja',
      colour: 'Cor',
      impermeability: 'impermeabilidade',
      sole: 'Sola',
      options: 'Opções',
      type: 'Tipo',
      category: 'Categoria',
      lens_category: 'Categoria de lente',
      lens_type: 'Tipos de lente',
      filter: 'Filtros',
      province: 'Província',
      region: 'Região',
      country: 'País',
      year: 'Ano',
      month: 'Mês',
      sensors: 'Sensores',
      connections: 'Conexões',
      autonomy: 'Autonomia (Modo GPS)'
    },
    filtersValues: {
      man: 'Homem',
      woman: 'Mulher',
      kid: 'Criança',
      low: 'Baixo',
      medium: 'Médio',
      high: 'Alto',
      mediumhigh: 'Médio alto',
      'carbon-plate': 'Placa de carbono',
      light: 'Fino',
      soft: 'Minimalista',
      strong: 'Forte',
      asphalt: 'Asfalto',
      trail: 'Trail',
      track: 'Pista',
      training: 'Treino',
      competition: 'Competição',
      mixed: 'Vários',
      dig: 'Cavo',
      flat: 'Plano',
      normal: 'Normal',
      tiptoe: 'Frente do pé',
      heel: 'Calcanhar',
      pronator: 'Pronador',
      neutral: 'Neutro',
      supinator: 'Supinador',
      short: 'Distâncias curtas',
      maraton: 'Maratona',
      'half-maraton': 'Meia maratona',
      '10k': '10K'
    }
  },
  product_sorting: [
    {
      value: 'recomendado_desc',
      text: 'relevância'
    },
    {
      value: 'data_desc',
      text: 'novidades'
    },
    {
      value: 'preço_asc',
      text: 'preço de menor para maior'
    },
    {
      value: 'preço_desc',
      text: 'preço de maior para menor'
    },
    {
      value: 'usuários_desc',
      text: 'pontuações dos usuários'
    },
    {
      value: 'review_desc',
      text: 'pontuação do especialista'
    },
    {
      value: 'desconto_desc',
      text: 'maior poupança'
    }
  ],
  price_sorting: [
    {
      value: 'data_desc',
      text: 'novidades'
    },
    {
      value: 'preço_asc',
      text: 'preço do menor para o maior'
    },
    {
      value: 'preço_desc',
      text: 'preço do maior para o menor'
    }
  ],
  author: {
    written: 'Escreveu',
    of: 'de',
    reviews: 'reviews',
    articles: 'artigos',
    by: 'Por',
    Twitter: 'Podes seguir-me no Twitter',
    Instagram: 'Podes seguir-me no Instagram'
  },
  cookies: {
    text: 'Utilizamos cookies próprias para manter identificados os utilizadores registados e poder personalizar os serviços e cookies de terceiros para estatísticas das páginas vistas mas sempre de uma forma anónima.',
    button: 'Política de cookies',
    accept: 'Aceito',
    deny: 'Não aceito'
  },
  article_list: {
    page_title: 'Magazine running',
    meta_title: 'Artigos running - roupa e sapatilhas de corrida',
    meta_description: 'Artigos de running publicados por corredores e pela equipa de Runnea com conselhos de nutrição, treino, sapatilhas de corrida para correr de forma saudável.',
    page_title_section: '{seo}',
    meta_title_section: 'Artigos {seo}',
    meta_description_section: 'Artigos de {seo} publicados por corredores Runnea. Conselhos de nutrição, treino e sapatilhas de corrida para correr de forma saudável.',
    page_title_author: 'Magazine running de {seo}',
    meta_title_author: 'Artigos running {seo} - Roupa e sapatilhas para corrida',
    meta_description_author: 'Artigos de running de {seo} com conselhos de nutrição, treino e sapatilhas de running para correr de forma saudável.',
    page_title_text: "Magazine running '{seo}'",
    meta_title_text: "Artigos running '{seo}' - Roupa e sapatilhas para corrida",
    meta_title_tag_section: '{seo} - Roupa e sapatilhas para corrida',
    comparator: 'Comparador',
    nutrition: 'Nutrição',
    running_training: 'Treino de corrida',
    sports_injuries: 'Lesões desportivas',
    running_news: 'Notícias de corrida',
    podcast: 'Podcast',
    outdoor: 'Outdoor',
    electronics: 'Electrónica',
    magazine: 'Magazine'
  },
  article_page: {
    magazine: 'RUNNEA Magazine',
    articles: 'Artigos',
    categories: 'Categorias',
    share: 'Partilha',
    follow: 'Segue-nos',
    comments: 'Comentários',
    related_articles: 'Artigos relacionados',
    leave_comment: 'Deixa o teu comentário',
    registered: 'Tens que estar registado para comentar',
    register: 'Regista-te',
    post: 'Publicar',
    notifications: 'Queres receber notificações quando um utilizador participar nesta conversa?',
    subscribe: 'Subscreve',
    reportage: 'Reportagem',
    copy_to_clipboard: 'Copiar o URL',
    copied_to_clipboard: 'URL copiado',
    posted_on: 'Publicado em',
    read_more_category: 'Leia mais notícias sobre:'
  },
  author_list: {
    page_title: 'Especialistas em running',
    meta_title: 'Especialistas em running, nutrição, treinos e testes de sapatilhas de corrida',
    meta_description: 'Especialistas em running, nutrição, treino e sapatilhas de corrida para correr de forma saudável e ajudar-te a escolher as sapatilhas de corrida que melhor se adaptam às tuas características e comprá-las ao melhor preço.',
    page_title_articles: 'Autores de artigos sobre running',
    meta_title_articles: 'Especialistas em running, nutrição, treinos e testes de sapatilhas de corrida',
    meta_description_articles: 'Especialistas em running, nutrição, treino e sapatilhas de corrida para correr de forma saudável e ajudar-te a escolher as sapatilhas de corrida que melhor se adaptam às tuas características e comprá-las ao melhor preço.',
    page_title_reviews: 'Provadores de {seo}',
    meta_title_reviews: 'Provadores de {seo}',
    meta_description_reviews: 'Especialistas em running, nutrição, treino e sapatilhas de corrida para correr de forma saudável e ajudar-te a escolher as sapatilhas de corrida que melhor se adaptam às tuas características e comprá-las ao melhor preço.',
    authors: 'autores.',
    showing_from: 'A mostrar do',
    showing_to: 'até ao'
  },
  author_page: {
    magazine: 'Magazine running de',
    articles: 'artigos'
  },
  brand_list: {
    brands: 'Marcas',
    title: 'Marcas de produtos de running | Runnea',
    description: 'Todas as marcas de produtos de running do nosso catálogo, de ajuda para escolher a melhor sapatilha para correr',
    marks: 'Marcas de produtos de running'
  },
  store_page: {
    brands: 'Principais marcas',
    categories: 'Categorias de produtos',
    since: 'Loja colaboradora com a Runnea desde ',
    reviews: 'Reviews',
    best_sold: 'O mais visto de'
  },
  calculator: {
    page_title: 'Calculadora de tempos',
    configure_sessions: 'Configura as tuas sessões de treinos e nós calculamos a soma de todos os intervalos, os parciais de cada intervalo e os parciais por quilómetros. Além disso, podes imprimir o resultado ou guardá-lo para poder vê-lo quando quiseres.',
    how_works: 'Como funciona',
    fill_two: 'Preenche dois dos três campos',
    of_each_interval: 'de cada intervalo e nós calcularemos o terceiro.',
    insert_up: 'Introduz até 20 intervalos.',
    added_intervals: 'Os intervalos adicionados podem ordenar-se ou eliminar-se.',
    can_also: 'Podes também inserir um grupo de intervalos',
    to_which: 'ao qual poderás atrbuir um número de repetições. Dentro do grupo, os intervalos podem ordenar-se ou eliminar-se.',
    save_your: 'Guarda as tuas sessões de treino e poderás aceder às mesmas quando quiseres.',
    to_save: 'Para guardar sessões tens que estar registado.',
    interval: 'Intervalo',
    distance: 'Distância',
    acronym_meters: '(Mts)',
    rhythm: 'Ritmo',
    acronym_rhythm: '(Min/Km)',
    time: 'Tempo',
    actions: 'Ações',
    raise_position: 'Subir posição',
    lower_position: 'Baixar posição',
    remove: 'Eliminar',
    new_interval: 'Novo grupo de intervalo',
    total: 'Total',
    see_hide: 'Ver / ocultar parciais',
    print: 'Imprimir',
    save: 'Guardar',
    modify: 'Modificar',
    group: 'Grupo',
    delete_all: 'Apagar tudo',
    calculate: 'Calcular',
    calculate_training: 'Calcula os tempos do treino',
    calculate_marathon: 'Calcula os tempos de uma maratona',
    calculate_half_marathon: 'Calcula os tempos de uma meia maratona',
    calculate_ten: 'Calcula os teus tempos de uma prova de 10k.',
    most_search: 'O mais procurado:',
    adv: 'Adv.',
    banner: 'Banner',
    reps: 'Reps.',
    error_missing: 'Um dos intervalos não tem os valores necessários',
    error_empty: 'Apagar algum dos campos para poder calcular de novo',
    error_not_valid: 'Estes valores não são válidos, por favor corrige-os.',
    error_need: 'Necessitamos um mínimo de dois valores para fazer o cálculo'
  },
  calculator_10K: {
    page_title: 'Calculadora de ritmos de 10K',
    insert_rhythm: 'Insere o teu ritmo e nós calculamos o tempo que farias numa prova de 10 km ou insere o tempo que queres fazer e nós calculamos o ritmo que necessitas para fazer os 10 Km.'
  },
  calculator_half_marathon: {
    page_title: 'Calculadora de tempos Meia Maratona',
    insert_rhythm: 'Insere o teu ritmo e nós calculamos o tempo que farias uma meia maratona ou insere o tempo que queres fazer e nós calculamos o ritmo que necessitas para fazer uma meia maratona.'
  },
  calculator_marathon: {
    page_title: 'Calculadora de tempos Maratona',
    insert_rhythm: 'Insere o teu ritmo e nós calculamos o tempo que farias uma maratona ou insere o tempo que queres fazer e nós calculamos o ritmo que necessitas para fazer uma maratona.'
  },
  check_in: {
    checking_user: 'Estamos a confirmar o teu utilizador'
  },
  comparator_page: {
    page_title: 'Comparador de {seo}',
    meta_title: 'Comparador de {seo}',
    meta_description: 'Comparador de {seo}, de ajudar para escolher {seo_the_best}.',
    in: 'Em',
    add: 'Acrescentar',
    best: 'Melhor',
    price: 'preço',
    money_symbol: '€',
    reviews: 'Reviews',
    queries: 'Questões',
    benefits: 'Prestações',
    characteristics: 'Características'
  },
  comparison_page: {
    page_title: '{seo}',
    meta_title: 'Comparativa - {seo}',
    meta_description: 'Dados para comparar e decidir se comprar {seo}, vendo a comparativa online de apenas uma página web.',
    in: 'Em',
    best: 'Melhor',
    price: 'preço',
    money_symbol: '€',
    opinions: 'Opiniões',
    queries: 'Questões',
    benefits: 'Prestações',
    characteristics: 'Características',
    soon: 'Em breve'
  },
  componentes: '[Record]',
  discount_codes_list: {
    page_title: 'Cupões de desconto em lojas de running {year}',
    meta_title: 'Cupões de desconto para que compres as tuas sapatilhas de running mais baratas',
    meta_description: 'Todos os cupões de descontos disponíveis em lojas de running para que as tuas compras saiam mais baratas',
    go_to_shop: 'Ir à loja',
    coupon: 'Código',
    copy_code: 'Copiar código'
  },
  gallery_page: {
    review: 'Review',
    opinions: 'Opiniões',
    prices: 'Preços',
    queries_recommendations: 'Questões e recomendações',
    photos: 'Fotos',
    news: 'Notícias'
  },
  index: {
    covers: 'Capas',
    user_profile: 'Perfil do utilizador',
    sheet: 'Ficha',
    sheet_of: 'Ficha de',
    product: 'Produto',
    products: 'Produtos',
    prices: 'Preços',
    race: 'Corrida',
    marks: 'Marcas',
    article: 'Artigo',
    articles: 'Artigos',
    reviews: 'Reviews',
    author: 'Autor*',
    authors: 'Autores',
    cupons: 'Cupões de desconto',
    popular_race: 'Corridas populares',
    query: 'Questão',
    queries: 'Questões',
    galery: 'Galeria',
    comparative: 'Comparativo',
    list: 'Lista',
    list_of: 'Lista de',
    filter_product: '(filtrado por produto)',
    filter_author: '(filtrado por autor)',
    filter_two: '(filtrado por autor e produto)',
    conList: 'Artigos, autores, questões e galerias',
    calculator: 'Calculadora',
    calculators: 'Calculadoras',
    marathon: 'Maratona',
    half_marathon: 'Meia maratona',
    ten_k: '10 km',
    link: 'Links',
    redirecting: 'A redirecionar a um preço externo',
    components: 'Componentes',
    crisis: '(Crisiscreativa),'
  },
  inquiry_list: {
    meta_title: '{seo} | Conselhos Runnea',
    meta_description: '',
    query: 'questão',
    inquiries: 'questões',
    inquiries_pl: 'questões',
    inquiries_types_todas: 'Todas',
    inquiries_types_1: 'A minha sapatilha ideal',
    showing_from: 'A mostrar do',
    showing_to: 'ao',
    find: 'Procurar',
    quick_search: 'Pesquisa rápida',
    category: 'Categoria',
    ask: 'Realizar uma',
    consultorio: 'Consultório Runnea',
    make_a: 'Realizar uma',
    required_field: 'Campo obrigatório',
    receive_notifications: 'Quero receber notificações quando um utilizador participar nesta questão?',
    consult: 'Fazer uma pergunta',
    thanks: 'Obrigado pela tua questão',
    error_try_again: 'Houve um erro no envio da tua questão. Tenta de novo mais tarde.',
    write: 'Escreve aqui o teu texto',
    search_advanced: 'Pesquisa avançada',
    search_keyword: 'Palavra chave'
  },
  landing_page: '[Record]',
  price_list: {
    outlet: 'outlet',
    page_title: 'Outlet de {seo}',
    subpage_title: 'Saldos {year} grandes descontos {seo}',
    meta_title: 'Outlet de {seo} - Ofertas para comprar online e opiniões',
    meta_description: 'Lista de preços de {seo}, com opiniões e avaliações de utilizadores para escolher a melhor sapatilha de running para desportistas ao melhor preço.',
    in_value: 'em {value}',
    of_value: 'de {value}',
    for_value: 'para {value}',
    with_value: 'com {value}',
    only_value: '{value} ',
    filter_value: '{filter} {value}',
    in_filter_value: 'em {filter} {value}',
    of_filter_value: 'de {filter} {value}',
    for_filter_value: 'para {filter} {value}',
    order_by: 'Ordenar por',
    show: 'A mostrar',
    shops: 'lojas',
    lowcost: 'Baratas',
    not_found: 'Não foram encontrados preços de',
    not_found_extra: 'para os filtros selecionados.',
    page: 'Página',
    prices_of_product_type: 'Preços de {type}',
    of: 'de'
  },
  product_list: {
    page_title: 'Produtos{filtros}',
    search_title: 'Resultados para ',
    search_product_title: 'Resultado de produtos para a pesquisa',
    search_article_title: 'Artigos que contêm',
    not_found: 'Não encontramos produtos nem artigos para a tua pesquisa',
    feedback_text: 'Não encontras o que estás à procura?',
    feedback_action: 'Envia-nos as tuas sugestões',
    feedback_link: '/sugestoes.html',
    meta_title: 'Produtos{filtros} - Ofertas para comprar online',
    meta_description: 'Lista de Produtos de Running{filtros}, com opiniões e avaliações de corredores para escolher os melhores produtos de running e corrida ao melhor preço',
    list: 'Lista de',
    show: 'A mostrar',
    of: 'de',
    pag: 'Página',
    list_of: 'Lista de',
    filters: 'Filtros',
    brand: 'Marca',
    gender: 'Género',
    price: 'Preço',
    drop: 'Drop',
    droptie: 'Drop',
    cushioning: 'Amortecimento',
    constitution: 'Constituição',
    footprint: 'Pisada',
    surface: 'Superfície',
    use: 'Uso',
    beat: 'Ritmo',
    foot: 'Pé',
    support: 'Apoio',
    distance: 'Distância',
    size: 'Tamanhos',
    technology: 'Tecnologia',
    fabric: 'Tecido',
    store: 'Loja',
    man: 'Homem',
    woman: 'Mulher',
    kid: 'Criança',
    low: 'Baixo',
    medium: 'Médio',
    mediumhigh: 'Médio alto',
    high: 'Alto',
    'carbon-plate': 'Placa de carbono',
    light: 'Fina',
    soft: 'Minimalista',
    strong: 'Forte',
    asphalt: 'Asfalto',
    trail: 'Trail',
    track: 'Pista',
    training: 'Treino',
    competition: 'Competição',
    mixed: 'Vários',
    dig: 'Cavo',
    flat: 'Plano',
    normal: 'Normal',
    tiptoe: 'Frente do pé',
    heel: 'Calcanhar',
    pronator: 'Pronador',
    neutral: 'Neutro',
    supinator: 'Supinador',
    short: 'Distâncias curtas',
    maraton: 'Maratona',
    'half-maraton': 'Meia maratona',
    '10k': '10K',
    in_value: 'em {value}',
    of_value: 'de {value}',
    for_value: 'para {value}',
    with_value: 'com {value}',
    only_value: '{value}',
    filter_value: '{filter} {value}',
    in_filter_value: 'em {filter} {value}',
    of_filter_value: 'de {filter} {value}',
    for_filter_value: 'para {filter} {value}',
    order_by: 'Ordenar por',
    visit: 'Visita o nosso',
    text: 'Texto',
    type: 'Tipo',
    outlet: 'outlet',
    showing_from: '(A mostrar resultados do',
    to: 'ao',
    new: 'Novo',
    products: 'Produtos',
    the: 'as',
    most: 'mais',
    sold_out_fem: 'vendidas',
    sold_out_masc: 'vendidos',
    about: 'sobre',
    relevance: 'relevância',
    module_products_opinion_female: '{products} mais comentados pelos utilizadores',
    module_products_opinion_male: '{products} mais comentados pelos utilizadores',
    module_reviews: 'Últimas reviews de {products}',
    top_seller: 'O mais vendido',
    position: 'Posição',
    show_more: 'Ver mais',
    product_list_show: '<strong>{totalProducts} {productCategory} {productSaga}</strong> (A mostrar resultados do {start} ao {end})',
  },
  product_page: {
    analysis: 'Análise',
    compare: 'Comparar',
    comparer: 'Comparador',
    show: 'Ver',
    show_prices: 'Ver preços',
    show_prices_man: 'Ver preços de homem',
    show_prices_woman: 'Ver preços de mulher',
    show_more_large: 'Mais informação sobre este modelo',
    show_more: 'Ver mais',
    note: 'Nota do',
    note_fem: 'Nota da',
    specialist: 'Especialista',
    community: 'Comunidade',
    leaf: 'Ficha',
    review: 'Review',
    review_title: 'Review de {product}',
    reviews: 'Reviews',
    reviews_likes_s: 'Uma pessoa considerou útil',
    reviews_likes_p: '{number} pessoas consideraram útil',
    reviews_like: 'Útil',
    photos: 'Fotos',
    opinions: 'Opiniões',
    opinions_of: 'Opiniões de',
    inquiries: 'Questões',
    inquiries_of: 'Questões de',
    similars: 'Similares',
    news: 'Notícias',
    news_of: 'Notícias de',
    prices: 'Preços',
    from: 'Desde',
    in: 'Em',
    shops: 'Lojas',
    collect: 'Reunimos preços de',
    more: 'Mais de',
    sellers: 'Vendedores',
    save: 'Poupa de',
    save_and: 'até ',
    save_to: 'Poupa até',
    releases: 'em novidades',
    rest: 'e no resto',
    size: 'Tamanhos',
    size_no: 'Não sabes o tamanho?',
    query_table: 'Consulta a tabela de tamanhos de',
    username: 'Nome do utilizador',
    user_querys: 'Questões dos utilizadores',
    doubts: 'Tens dúvidas?',
    query_expert: 'Questiona o especialista',
    compare_with: 'Comparar com',
    compare_with_label: 'Comparar ',
    with: 'com',
    conclusion: 'Conclusão',
    average_rating: 'Avaliação média',
    user_average_rating: 'Avaliação média dos utilizadores',
    user_rating: 'Avaliação do utilizador',
    from_users: 'Dos utilizadores',
    lightness: 'Leveza',
    balance: 'Estabilidade',
    response: 'Resposta',
    flexibility: 'Flexibilidade',
    damping: 'Amortecimento',
    grip: 'Tração',
    rate_product: 'Avalia estas sapatilhas',
    rated: 'utilizadores avaliaram este modelo de',
    find: 'Encontra',
    displaying: 'A mostrar',
    of: 'de',
    querys: 'Questões',
    querys_comments: 'Questões e comentários',
    querys_comments_of: 'Questões e comentários de',
    show_comments: 'Ver comentários',
    make_query: 'Quero realizar uma questão',
    news_talk: 'notícias que falam sobre estas sapatilhas',
    community_talk: 'Confia na comunidade Runnea, os nossos utilizadores falam',
    this: 'destas',
    this_masc: 'destes',
    features: 'Características -',
    features_of: 'Características das',
    features_and_opinions: 'review e opiniões',
    best_information: 'com informação para escolher a melhor ',
    buy_at: 'Comprar ao melhor preço',
    best_price: 'Melhor preço',
    benefits: 'Prestações',
    photo_gallery: 'Galeria de fotos',
    original_price: 'Preço original',
    gender: 'Género',
    sizes: 'Tamanhos',
    choose_size: 'Seleciona o teu tamanho',
    no_prices_soon: 'Nos últimos dias, não detectámos preços de:',
    no_prices_soon_vars: 'Nos últimos dias, não detectamos preços para: {product} com tamanho {filtersSize}. Tente selecionar um tamanho diferente.',
    your_selection: 'Sua Seleção',
    no_prices: 'Sê o primeiro a receber um aviso quando tenhámos preços disponíveis',
    photo_galery: 'Galeria de fotos',
    lower_query: 'questões',
    lower_opinions: 'opiniões',
    min: 'Min:',
    max: 'Máx:',
    anonymous: 'Anónimo',
    no_comments: 'Sem comentários',
    no_queries: 'Não há questões',
    no_opinions: 'Não há opiniões',
    be_first_opinion: 'Sê o primeiro a comentar',
    more_compared: 'os que mais se comparam',
    size_guide: 'Guia de tamanhos',
    official_store: 'Loja oficial',
    tester: 'Tester',
    testers: 'Os nossos testers contam-te a sua opinião',
    added_product: 'O produto foi adicionado ao comparador',
    go_to_comparator: 'Ir ao comparador',
    see_more_prices: 'Ver mais preços',
    more_prices_text: 'Temos mais preços',
    more_prices_of: 'de',
    featured_prices: 'Preços destacados',
    also_from: 'Também de',
    characteristics: 'Características da',
    with_information: ', com informação para escolher a melhor',
    money_acronim: 'EUR',
    see: 'Ver',
    products_of: 'Produtos de',
    related_to: 'relacionados com esta pesquisa',
    page: 'Página',
    lower_page: 'página',
    score_text: 'Nota RUNNEA',
    video: 'Vídeo',
    watch_video: 'Ver vídeo review',
    runnea_score_title: 'O que é o RUNNEA SCORE?',
    runnea_score_info: 'É a pontuação gerada com base nas características do produto, o interesse e opiniões dos utilizadores e a relação preço/qualidade.',
    ranking_text: '<strong>{product}</strong> ocupa a <b>posição {position}</b> no ranking de {type} com melhor avaliação.',
    ranking_view: 'Ver ranking',
    awards: 'Prémios',
    pros: 'Vantagens',
    cons: 'Inconvenientes',
    question: 'Pergunta',
    question_first_letter: 'P',
    answer: 'Resposta',
    answer_first_letter: 'R',
    answers: 'Respostas',
    fan_title: 'És adepto de',
    fan_text: 'Ver todos os modelos de:',
    expert: 'Especialista',
    need_know: 'O que precisa de saber',
    runner_type: 'Tipo de corredor',
    ideal_for: 'Ideal para',
    strengths: 'Pontos fortes',
    rivals: 'Rivais',
    weight: 'Peso'
  },
  product_prices: {
    page_title: '{seo}',
    meta_title: '{seo} | {precio_desde} | {fecha}{pagina}',
    meta_gender_title: '{seo} | {precio_desde} | {fecha}{pagina}',
    meta_description: 'Lista de preços de {seo}, com ofertas para comprar online',
    best_offer: 'A melhor oferta',
    cheap: 'baratas',
    showing: 'A mostrar',
    showing_total_prices: 'A mostrar {total} {seo}',
    users_favourites: 'As favoritas dos Runneantes',
    compare_with: 'Comparar com',
    find_more_products: 'Encontra {type} {brand}',
    in_value: 'em {value}',
    of_value: 'de {value}',
    for_value: 'para {value}',
    with_value: 'com {value}',
    only_value: '{value}',
    filter_value: '{filter} {value}',
    in_filter_value: 'em {filter} {value}',
    of_filter_value: 'de {filter} {value}',
    for_filter_value: 'para {filter} {value}',
    show_prices: 'Ver preços',
    alert: 'Avisamos-te quando estiverem disponíveis',
    add_alert: 'Ativar alerta',
    average_grade: 'Nota média dos corredores',
    favorites: 'As favoritas dos runneantes',
    fill_position: 'Ocupa o posto',
    best_note: 'com melhores avaliações dos utilizadores',
    of: 'de',
    from: 'de',
    to: 'ao',
    prices: 'Preços',
    order_by: 'Ordenar por',
    show_filters: 'Mostrar filtros',
    prices_of: 'Preços de sapatilhas de running',
    page: 'Página',
    lower_page: 'página',
    not_found: 'Não se encontraram resultados de',
    for_selected: 'para os filtros selecionados',
    send: 'enviar',
    country_from: 'Você está visitando de {country}?',
    country_change: 'Escolha sua localização geográfica'
  },
  race_list: {
    page_title: 'Corridas Populares{seo}',
    meta_title: 'Corridas Populares{seo}, Calendário das melhores corridas',
    meta_description: 'Calendário de corridas populares{seo}, informação completa sobre inscrições, percurso, local e hora de saída.',
    showing: 'A mostrar',
    showing_total_races_seo: 'A mostrar {total} corridas populares{seo}',
    in_value: 'em {value}',
    of_value: 'de {value}',
    for_value: 'para {value}',
    with_value: 'com {value}',
    only_value: '{value}',
    filter_value: '{filter} {value}',
    in_filter_value: 'em {filter} {value}',
    of_filter_value: 'de {filter} {value}',
    for_filter_value: 'para {filter} {value}',
    race_year: 'Running: Corridas Populares em {year}',
    find: 'Encontra a tua corrida',
    not_found: 'Não foram encontrados conteúdos para esta pesquisa',
    description: 'Data, prazo de inscrição, percurso e classificações de',
    start: 'Partida',
    filters: {
      distance: 'Distância',
      surface: 'Superfície',
      province: 'Província',
      country: 'País',
      month: 'Mês',
      year: 'Ano'
    }
  },
  race_page: {
    meta_title: '{seo}',
    meta_description: 'Informação de ajuda para correr em {seo} com dados de inscrições, percurso, local e hora de saída, classificações, corredores e planos de treino.',
    tagline: 'Data, inscrições, percurso e informação geral',
    browser_title: 'Running: Corridas Populares em {year}',
    races_title: 'Corridas Populares',
    score_general: 'Geral',
    score_route: 'Percurso',
    score_organization: 'Organização',
    score_ambience: 'Ambiente',
    score_refreshments: 'Abastecimentos',
    poster: 'Póster',
    average_rating: 'Avaliação média',
    from_users: 'Dos utilizadores',
    view_race_web: 'Ver página oficial da corrida',
    leaf: 'Ficha',
    inscriptions: 'Inscrições',
    route: 'Percurso',
    forum: 'Fórum',
    verify: 'Verificar',
    feature_date: 'Data',
    feature_place: 'Local',
    feature_province: 'Província',
    feature_country: 'País',
    feature_surface: 'Superfície',
    feature_time: 'Hora de partida',
    feature_participants: 'Participantes',
    feature_contact: 'Contacto',
    feature_organizer: 'Organizador',
    feature_distance: 'Distância',
    something: 'Algo',
    heart_rate_monitors: 'Pulsómetros',
    popular_races: 'Corridas populares',
    outlet: 'Outlet',
    description: 'Descrição',
    will_be: 'realiza-se',
    in: 'em',
    anyway_is_important: 'De todas as formas, é importante que confirmes a data na página oficial do Organizador',
    as_organizers: 'Assim que os organizadores nos disponibilizem o percurso desta edição ou se abra o prazo de inscrições, publicaremos a informação correspondiente, mas se tens alguma dúvida sobre esta corrida não hesites em enviar as tuas perguntas a',
    of: 'de',
    or_consult: 'ou consultar a página oficial da organização da corrida.',
    inscription: 'Prazos de inscrição para a',
    if_you_want: 'Se queres saber quando abre o prazo de inscrições desta edição da',
    be_aware: 'fica atento à nossa página e ao nosso fórum da',
    because: 'porque vamos informar de forma atempada.',
    unfold_info: 'À medida que sejam revelados detalhes do percurso desta edição da',
    will_inform: 'iremos informando.',
    questions_doubts: 'Se tens alguma questão ou dúvida sobre a corrida, podes enviar as tuas perguntas ao nosso fórum da',
    fill_form: 'Preenche este formulário para que o validemos',
    organizer: 'És o organizador da corrida?',
    verify_race: 'Verificar corrida'
  },
  redirecting: {
    redirecting: 'Estamos a encaminhar-te para a página oficial.',
    value_proposal: 'Na RUNNEA, comparamos os preços em mais de 150 lojas online verificadas para oferecer-te o melhor resultado, sem custos adicionais.',
    comission: 'Podemos receber uma comissão se visitares ou comprares através do nosso Website.',
    coupon_advice: 'Para beneficiar o preço em que clicaste, não te esqueças de utilizar o código de desconto!',
    price: 'Preço RUNNEA: ',
    pvp: 'PVP: ',
    updated: 'Atualizado',
    accept_button: 'Entendido'
  },
  review_list: {
    page_title: 'Reviews de {seo}',
    meta_title: 'Reviews de {seo}',
    meta_description: 'Reviews de {seo} | Runnea, ajuda para escolher a melhor sapatilha para correr.',
    reviews: 'reviews | review | reviews',
    showing_from: 'A mostrar do',
    showing_to: 'ao'
  },
  opinion_list: {
    page_title: 'Opiniões de {seo}',
    meta_title: 'Opiniões de {seo}',
    meta_description: 'Opiniões {seo} | Runnea, ajuda para escolher a melhor sapatilha para correr.',
    opinions: 'opiniões | opinião | opiniões',
    showing_from: 'A mostrar do',
    showing_to: 'ao'
  },
  login: {
    session: 'Iniciar sessão',
    welcome: 'Bem-vindo Runneante',
    forget: 'Esqueceste a tua palavra passe',
    send: 'Enviar',
    back: 'Retroceder',
    genericerror: 'Erro de login',
    closeSession: 'Terminar sessão',
    no_account: 'Não tenho conta, quero registar-me',
    logout: 'Logout',
    login: 'Login',
    register: 'Registar',
    incorrect_email: 'Email incorreto',
    incorrect_password: 'Palavra passe incorreta',
    remmember_password: 'Guardar palavra passe'
  },
  reset_password: {
    new: 'Nova palavra passe',
    repeat: 'Repete a palavra passe',
    send: 'Enviar',
    reset_password: 'Introduz a tua nova palavra passe de início de sessão',
    change: 'Mudar palavra passe',
    different_passwords: 'As palavras passe são diferentes',
    description: 'A palavra passe deve conter pelo menos 8 caracteres e conter minúsculas, maiúsculas e números',
    successfully_update: 'Mudaste a tua palavra passe. Já podes fazer login para iniciar sessão',
    unknown_error: 'Erro desconhecido',
    error: 'Erro'
  },
  register: {
    username: 'Nome de utilizador',
    email: 'Email',
    name: 'Nome',
    sex: 'Sexo',
    man: 'Homem',
    woman: 'Mulher',
    country: 'País',
    postalCode: 'Código postal',
    brand: 'Tens uma marca favorita?',
    size: 'Tamanho',
    send: 'Enviar',
    facebook: 'Liga-te com o Facebook',
    google: 'Liga-te com o Google',
    password: 'Palavra passe',
    repeat_password: 'Repete a palavra passe',
    Emailnotcorrect: 'O email não é correto',
    Passwordsdontmatch: 'As palavras passe não coincidem',
    LowercaseLetterRequired: 'É exigida uma minúscula.',
    UppercaseLetterRequired: 'É exigida uma maiúscula.',
    '8Characters': 'Mínimo de 8 caracteres.',
    OneNumber: 'É exigido um número.',
    PasswordIncorrect: 'A palavra passe não é correta. Verifica se tem minúsculas, maiúsculas, mais de 8 caracteres e números.',
    registrate: 'Regista-te',
    successfully_registered: 'Registado com sucesso',
    not_exist_email: 'O email não existe',
    remember_ok: 'Enviámos um email para {email}. Verifica a caixa de entrada para recuperar a tua palavra passe.',
    user_not_found: 'Utilizador não encontrado',
    error_recovering: 'Erro ao recuperar os dados',
    email_exists: 'O email já existe',
    email_not_found: 'Não foi possível encontrar o email',
    missing_fields: 'Faltam completar campos',
    incorrect_format: 'Formato incorreto'
  },
  verify: {
    name_surname: 'Nome e apelido',
    email: 'Email',
    tlf: 'Número de contacto',
    days: 'Dias da semana em que podemos entrar em contacto contigo',
    hour: 'Horário de disponibilidade',
    send: 'Enviar',
    monday: 'Segunda-feira',
    tuesday: 'Terça-feira',
    wednesday: 'Quarta-feira',
    thursday: 'Quinta-feira',
    friday: 'Sexta-feira',
    extra: 'Informação adicional',
    emailnotcorrect: 'O email não é correto',
    verified: 'Obrigado por preencheres o formulário. Vamos entrar em contacto contigo para confirmar a informação',
    not_verified: 'Lamentamos, algo correu mal. Tenta mais tarde.',
    missing_fields: 'Faltam completar campos',
    accept: 'Aceita a',
    acceptPol: 'política de privacidade',
    acceptText: 'Trataremos os teus dados para entrar em contacto contigo e finalizar o processo de verificação. Podes exercer os teus direitos de acesso, retificação, supressão, oposição, limitação do tratamento e portabilidade escrevendo-nos para <a href="mailto:contacto@runnea.com" rel="nofollow">contacto@runnea.com</a>. Mais informação na <a href="https://www.runnea.com/politica-privacidad.html" target="_blank" rel="noopener noreferrer nofollow">Política de Privacidade</a>',
    form_first: 'És organizador do evento',
    form_first_after_bold: '?',
    form_second: 'Verifica os dados da corrida para dar mais visibilidade ao evento de forma gratuita.',
    form_third: 'Preenche o seguinte formulário e iremos contactar-te para finalizar o processo de verificação. '
  },
  registration: {
    already_account: 'Já tenho conta, quero iniciar sessão',
    continue: 'Registar-me'
  },
  talk_page: {
    consultant: 'Consultório'
  },
  tag_page: '[Record]',
  user_page: {
    modify: 'Modificar foto',
    save: 'Guardar alterações',
    select: 'Selecionar'
  },
  not_found_page: {
    pageNotFound: 'Página não encontrada',
    pageNotFoundMsg: 'Lamentamos, não encontramos a página. Podes retroceder ou voltar à página inicial.',
    goHome: 'Ir à página inicial'
  },
  article_finder: {
    find_here: 'Procurar artigos do magazine'
  },
  AuthorLeaf: {
    has_written: 'Escreveu',
    num_reviews_of: '0 reviews de | 1 review de | {count} reviews de',
    of: 'de',
    reviews: 'reviews',
    articles: 'artigos'
  },
  AuthorMiniLeaf: {
    by: 'Por'
  },
  BrandCard: {
    size_chart: 'Conversor de tamanhos',
    go_to_size_chart: 'Ir ao conversor de tamanhos'
  },
  BrandsProduct: {
    also: 'Também de',
    from: 'Desde',
    money: '€',
    find: 'Encontra sapatilhas de corrida'
  },
  DiscountCodeCard: {
    see: 'Ver desconto',
    share: 'Partilhar',
    go_to_store: 'Ir para a loja',
    copied: 'Copiado',
    special_cupon: 'Cupão especial',
    date_up_to: 'Até ao',
    read_more: 'Ler mais'
  },
  ModuleProductFeatureCard: {
    our_selection: 'Nossa seleção'
  },
  InquiryTitleCard: {
    other_queries: 'Outras questões de running'
  },
  ModuleCarousel: {
    new: 'Novidade!',
    upper_new: 'NOVAS',
    discover: 'Descobre-as',
    exclusive: 'Exclusivo',
    text_1: 'Chegaram as corridas virtuais Runnea Academy Runnea Academy Runnea Aca',
    text_2: 'Em Runnea trazemos-te a lista das melhores corridas virtuais a'
  },
  ModuleChats: {
    title: 'Questões e comentários de {content}',
    showing: 'A mostrar de {start} de {end} questões',
    show_all: 'Ver {total} questões dos utilizadores',
    no_contents: 'Não há questões nem comentários'
  },
  ModuleComments: {
    title: 'Comentários de {content}',
    showing: 'A mostrar {start} de {end} comentários ',
    show_all: 'Ver {total} questões dos utilizadores',
    no_contents: 'Não há comentários'
  },
  ModuleForm: {
    title: 'Contacto',
    field: 'O campo',
    is_required: 'é obrigatório',
    query_received: 'Recebemos a tua questão. Em menos de 48 horas iremos entrar em contacto contigo. Obrigado por contactar-nos, runneante.',
    error_submitting: 'Erro ao enviar o formulário',
    go_to_form: 'Ir para o formulário',
    confirmButton: 'Entendido'
  },
  ModuleMenu: {
    running_shoes: 'Sapatilhas de running',
    trekking_shoes: 'Sapatilhas de Trekking',
    trail_shoes: 'Sapatilhas de Trail',
    treadmill: 'Passadeiras',
    pulsometer: 'Pulsómetros',
    running_training: 'Treino de Running',
    menu: 'Menu',
    login: 'Iniciar sessão',
    logout: 'Terminar sessão',
    account: 'A minha conta',
    title_ra: 'Treinador pessoal Running e Trail Running Online',
    claim: 'Mais de 700.000 corredores confiam em Runnea',
    categories: 'Todas as categorias'
  },
  ModulePricesBargain: {
    new: 'Nova pechincha',
    discount: 'desconto',
    go: 'Ir para a pechincha',
    click: '1 clique',
    clicks: '{clicks} cliques'
  },
  newChatButton: {
    description: 'Se tens dúvidas num tema específico, podes escolher um especiailista para que ajudar. Se preferires, podes fazer uma pergunta à comunidade e poderás ter resposta de qualquer um dos nossos especialistas ou de outros utilizadores.',
    options: 'Escolhe uma opção',
    your_inquiry: 'A tua questão',
    your_inquiry_for: 'A tua questão para',
    to_do: 'Realizar',
    quest: 'Realizar uma questão',
    do: 'Realiza',
    inquiry: 'questão | questões',
    inquiries: 'sem questões | uma questão | umas questões',
    select_category: 'Seleciona uma categoria',
    inquiry_description: 'Descrição da questão',
    write_your_text: 'Escreve aqui o teu texto',
    required_field: 'Campo obrigatório',
    receive_notifications: 'Quero receber notificações quando um utilizador participar nesta questão',
    not_logged_in: 'Não iniciaste sessão',
    please_login_msg: 'Não iniciaste sessão. Por favor, inicia sessão',
    thanks: 'Obrigado pelo envio da tua questão.',
    try_again: 'Houve um erro a enviar a tua questão. Tenta mais tarde.',
    rgpd: 'Trataremos os teus dados para tramitar a tua questão e responder à mesma. Podes exercer os teus direitos de acesso, retificação, supressão, oposição, limitação de tratamento e portabilidade escrevendo-nos para <a href="mailto:contacto@runnea.com" rel="nofollow">contacto@runnea.com</a>. Mais informação na <a href="https://www.runnea.com/politica-privacidad.html" target="_blank" rel="noopener noreferrer nofollow">Política de Privacidade</a>',
    mentioned_shoes: 'Sapatilhas mencionadas',
    new_inquiry: 'Nova questão',
    new_inquiry_text: 'Se tens alguma dúvida sobre material de running, os nossos especialistas podem ajudar-te.',
    modal: {
      title: 'Pergunta a um especialista',
      text: 'Se necessitas esclarecer alguma dúvida sobre material de running escreve-nos e a nossa equipa de especialistas irá ajudar-te em tudo o possível.'
    }
  },
  newCommentButton: {
    reply: 'Responder',
    inquiry: 'questão | questões',
    write_your_text: 'Escreve aqui o teu texto',
    required_field: 'Campo obrigatório',
    receive_notifications: 'Quero receber notificações quando um utilizador participar nesta questão',
    not_logged_in: 'Não iniciaste sessão',
    please_login_msg: 'Não iniciaste sessão. Por favor, inicia sessão',
    thanks: 'Obrigado por participares nesta questão.',
    try_again: 'Houve um erro no envio do comentário. Tenta mais tarde.'
  },
  newOpinionButton: {
    new: 'Deixa-nos a tua opinião',
    not_logged_in: 'Não iniciaste sessão',
    please_login_msg: 'Não iniciaste sessão. Por favor, inicia sessão',
    performance: 'Prestações',
    comment: 'Comentário',
    opinion: 'Enviar comentário',
    score: 'Pontuação',
    write: 'Escreve a tua opinião',
    thanks: 'Obrigado pelo envio da tua opinião.',
    next: 'Em algumas horas poderás ver a tua opinião publicada no nosso site.',
    try_again: 'Houve um erro ao enviar a tua opinião ou já deixaste uma opinião anteriormente.'
  },
  newCommentModule: {
    leave_comment: 'Deixa o teu comentário',
    registered: 'Tens que estar registado para comentar',
    register: 'Regista-te',
    placeholder: 'Escreve a tua opinião',
    required_field: 'Campo obrigatório',
    post: 'Publicar',
    thanks_msg: 'Comentário enviado. Obrigado pela tua participação.',
    error_msg: 'Houve um erro no envio do comentário. Tenta mais tarde.',
    rgpd: 'Trataremos os teus dados para tramitar a tua questão e responder à mesma. Podes exercer os teus direitos de acesso, retificação, supressão, oposição, limitação de tratamento e portabilidade escrevendo-nos para <a href="mailto:contacto@runnea.com" rel="nofollow">contacto@runnea.com</a>. Mais informação na <a href="https://www.runnea.com/politica-privacidad.html" target="_blank" rel="noopener noreferrer nofollow">Política de Privacidade</a>'
  },
  NewsLettersCard: {
    message: 'Fica a par de todas as novidades sobre o mundo do running e das ofertas em material desportivo.',
    highlight: 'Vais perdê-lo?',
    remember: 'Lembra-te de aceitar os termos e condições.',
    successful: 'Já te subscrevemos à nossa newsletter, Runneante',
    error: 'Houve um  erro, Runneante. Tenta mais tarde.',
    error_try: 'Houve um  erro. Tenta mais tarde.',
    required: 'Campo obrigatório',
    thanks: 'Obrigado'
  },
  ProductCardPrincipal: {
    see: 'Ver ficha',
    see_description: 'Consulta todas as características'
  },
  ProductHeaderButtons: {
    alert_received: 'Configuramos o teu alerta corretamente. Irás receber um aviso por email no momento em que encontremos um preço inferior ou igual ao indicado. Obrigado por confiares em nós.',
    error_submitting: 'Não foi possível criar o alerta. Tenta de novo',
    compare: 'Comparar',
    alert: 'Avisa-me se o preço baixar',
    available: 'Avise-me assim que estiver disponível',
    disable_alert: 'Alerta',
    disable_price_alert: 'Desativar o alerta de preço',
    close: 'Fechar',
    our_comparator: 'O nosso comparador analisa todos os dias milhões de preços. Configura os teus alertas de preços e se encontrarmos um preço que cumpra os teus critérios iremos enviar-te um aviso.',
    model: 'Alerta de preço sobre',
    sure: 'Clica no botão “Confirmar” para desativar esta alerta de preço.',
    confirm: 'Confirmar',
    alert_deleted: 'Eliminaste com sucesso o alerta de preço que tinhas configurado sobre o modelo {marca} {producto}.',
    error_deleting_alert: 'Não conseguimos eliminar o alerta de preço que tinhas configurado sobre o modelo {marca} {producto}. Por favor, contacta-nos para que possamos eliminá-lo.',
    new_version: 'Nova versão disponível',
    new_version_btn: 'Ver preços',
    old_version: 'O modelo atual é:',
    old_version_btn: 'Ver Ficha',
    version: 'As <strong>{version}</strong> já estão disponíveis com preços em diferentes lojas',
    version_btn: 'Ver preços'
  },
  ProductFeedback: {
    chat_1: 'Olá{user}',
    chat_2: 'O que nos podes dizer sobre {product}?',
    state_wish: 'Quero',
    state_have: 'Já tenho',
    state_dislike: 'Não gosto',
    votes: 'votos',
    response_wait: 'Estamos a registar a tua participação…',
    response_ok: 'Obrigado por participares.',
    response_ko: 'Ops, houve um erro. Tenta de novo.'
  },
  ContentPremium: {
    title: 'Acede à tua conta para ver o conteúdo.',
    description: 'Cria a tua conta GRÁTIS e desfruta de todo o conteúdo exclusivo',
    advantages_1: 'Acesso ilimitado a artigo restringidos',
    advantages_2: 'Newsletters com conteúdo personalizado',
    advantages_3: 'Alertas e sorteios'
  },
  SliderCardFinder: {
    find: 'Procurar'
  },
  UserPasswordForm: {
    new_password: 'Introduz o teu email de Runnea e iremos enviar-te uma nova palavra passe.'
  },
  UserRegisterForm: {
    continue_with: 'Continua com',
    or: 'ou',
    start: 'Completa uns dados e começar a runnear!',
    required: 'Campo obrigatório',
    access_data: 'Os teus dados de acesso',
    incorrect_user: 'Utilizador incorreto',
    remmember_password: 'Guardar palavra passe',
    congratulations: 'Parabéns!',
    welcome: 'Damos-te as boas vindas à comunidade de Runnea',
    complete: 'Completa uns dados e começar a runnear!',
    no_account: 'Não tenho conta',
    name: 'Nome',
    surname: 'Apelidos',
    user: 'Utilizador',
    email: 'Correio eletrónico',
    password: 'Palavra passe',
    tread_type: 'Tipo de pisada',
    dont_know: 'Não sei',
    pronator: 'Pronadora',
    neutral: 'Neutra',
    supinator: 'Supinador',
    commercial_communications: 'Desejo receber comunicações comerciais por meios eletrónicos.',
    legal: 'Li e aceito as <a href="https://www.runnea.com/aviso-legal.html" target="_blank" rel="noopener noreferrer" class="legal_notice">Condições Particulares de Registo</a> em Runnea.',
    rgpd: 'Podes exercer os teus direitos de acesso, retificação, supressão, oposição, limitação de tratamento e portabilidade escrevendo-nos para <a href="mailto:contacto@runnea.com" rel="nofollow">contacto@runnea.com</a>. Mais informação na <a href="https://www.runnea.com/politica-privacidad.html" target="_blank" rel="noopener noreferrer nofollow">Política de Privacidade</a>.'
  },
  UserAuthForm: {
    wrong_password: 'Utilizador ou palavra passe incorreta',
    no_email: 'O email não existe'
  },
  influencer_card: {
    reviews: 'Reviews',
    last_review: 'Última review',
    best_review: 'Melhor avaliada',
    show_price: 'Ver preço'
  },
  inquiry_card: {
    comments: 'Ver comentários',
    no_comments: 'Sem comentários'
  },
  inquiry_finder: {
    find_text: 'Filtrar por texto',
    find_product: 'Filtrar por produto'
  },
  list_filters: {
    filters: 'Filtros',
    filter: 'Filtrar',
    return: 'Voltar',
    delete: 'Apagar',
    sizes: 'Tamanhos',
    show: 'Mostrar',
    results: 'Resultados',
    example: 'Ex. Nike',
    more_brands: 'Mais marcas'
  },
  list_pagination: {
    before: 'Anterior',
    next: 'Seguinte',
    first_page: 'Primeira página',
    last_page: 'Última página'
  },
  price_card: {
    shipping_costs: 'Envio',
    from_price: 'Desde {price} €',
    buy: 'Comprar',
    go_to_store_w_price: 'Ir para a loja de <i>{store}</i> e comprar por {price} €',
    free_shipping: 'Envio gratuito',
    show_offer: 'Ver oferta',
    till: 'Até',
    coupon: 'Com cupão',
    coupon_no: 'Sem cupão',
    man: 'Homem',
    woman: 'Mulher',
    kid: 'Criança',
    unisex: 'Homem - Mulher'
  },
  product_card_price_opinion: {
    let_opinion: 'Deixa uma opinião',
    opinions: 'Opiniões',
    questions: 'Questões'
  },
  product_finder: {
    find_here: 'Procura aqui'
  },
  brand_finder: {
    find_here: 'Filtrar por marca'
  },
  race_finder: {
    find_here: 'Procura uma corrida',
    search: 'Procurar'
  },
  module_finder: {
    title: 'Sapatilhas Running',
    text1: 'Encontra o melhor preço em mais de 20.000 sapatilhas diferentes',
    text2: 'Procuramos e comparamos mais de 200.000 sapatilhas das principais marcas',
    placeholder: 'Procurar por marca ou modelo',
    need_help: 'Precisas de ajuda con a tua procura?',
    just_ask: 'Pergunta aos nossos especialistas',
    where_start: 'Não sabe por onde começar?',
    use_shoe_finder: 'Experimenta o nosso recomendador de sapatilhas',
    search_by_brand: 'Procura por marca'
  },
  footer: {
    about: 'Sobre nós',
    newsletter: 'Newsletter',
    promotion: 'Promoção em RUNNEA',
    promotionMobile: 'Promoção na APP de RUNNEA',
    product_test: 'Teste de produto',
    shops: 'A tua loja e os teus produtos',
    products: 'Produtos',
    contact: 'Contacto',
    legal: 'Aviso legal',
    publicity: 'Publicidade',
    privacity: 'Política de privacidade',
    cookies: 'Política de Cookies',
    cookies_consent: 'Gerir cookies',
    work: 'Trabalha connosco',
    who: 'Quem somos',
    legal_information: 'Informação legal',
    other_webs: 'Outras páginas de RUNNEA',
    copyright: 'Copyright RUNNEA',
    license: 'SGAE. Nº de licença: SGAERRDD/1/1521/0322',
    follow: 'Segue-nos',
    union: 'UNIÃO EUROPEIA',
    union_deep: 'Fundo Europeu de Desenvolvimento Regional',
    union_mes: 'Gembira Tech, S.L. através do Programa de Iniciación a la Exportación ICEX Next, contou com o apoio do ICEX e com o cofinanciamento do fundo europeu FEDER. A finalidade deste apoio é contribuir para o desenvolvimento internacional da empresa e das que a rodeiam.',
    union_mode: 'Uma maneira de fazer Europa',
    disclaimer: 'RUNNEA pode receber uma comissão do vendedor se comprar através do link de afiliado.'
  },
  product_header: {
    specialist: 'Nota do especialista',
    community: 'Nota da comunidade',
    leaf: 'Ficha',
    review: 'Reviews',
    opinions: 'Opiniões',
    inquiries: 'Questões e recomendações'
  },
  user_auth_form: {
    facebook: 'Inicia sessão com o Facebook',
    google: 'Inicia sessão com o Google'
  },
  youtube: {
    suscribe: 'Subscreve o nosso canal de Youtube',
    review: 'Vídeo review'
  },
  app_finder: {
    example: 'Marca ou modelo…',
    popular: {
      title: 'Descobre',
      list: {
        1: {
          title: 'Sapatilhas de running para mulher',
          link: '/sapatilhas-running/modelos/todas/genero-mulher/'
        },
        2: {
          title: 'Sapatilhas de running para homem',
          link: '/sapatilhas-running/modelos/todas/genero-homem/'
        },
        3: {
          title: 'Sapatilhas trail',
          link: '/sapatilhas-running/modelos/todas/superficie-montanha/'
        },
        4: {
          title: 'Pulsómetros',
          link: '/relogios-desportivos/modelos/todas/'
        },
        5: {
          title: 'Treino de running',
          link: 'https://www.runnea.com/treino-running/app.html'
        }
      }
    },
    most_searched: {
      title: 'Tendências mais procuradas',
      list: {
        1: {
          title: 'Nike Pegasus 40',
          link: '/sapatilhas-running/nike/pegasus-40/1018009/',
          img: 'https://static.runnea.com/images/202305/nike-pegasus-40-zapatillas-running-400x400x90xX.jpg?1'
        },
        2: {
          title: 'ASICS Gel Nimbus 25',
          link: '/sapatilhas-running/asics/gel-nimbus-25/1006019/',
          img: 'https://static.runnea.com/images/202212/asics-gel-nimbus-25-zapatillas-running-400x400x90xX.jpg?1'
        },
        3: {
          title: 'Adidas Ultraboost Light 23',
          link: '/sapatilhas-running/adidas/ultraboost-23/1005798/',
          img: 'https://static.runnea.com/images/202302/adidas-ultraboost-23-zapatillas-running-400x400x90xX.jpg?1'
        },
        4: {
          title: 'New Balance FuelCell SC Elite v3',
          link: '/sapatilhas-running/new-balance/fuelcell-sc-elite-v3/1004896/',
          img: 'https://static.runnea.com/images/202302/new-balance-fuelcell-sc-elite-v3-zapatillas-running-400x400x90xX.jpg?1'
        },
        5: {
          title: 'Garmin Fenix 7',
          link: '/relogios-desportivos/garmin/fenix-7/17102/',
          img: 'https://static.runnea.com/images/202201/garmin-fenix7-ficha-caracteristicas-precios-400x400x80xX.jpg?1'
        }
      }
    }
  },
  consent_cookie: {
    consent_modal: {
      title: 'Utilizamos cookies para oferecer-te a melhor experiência',
      description: 'Utilizamos cookies próprios para assegurar-nos do funcionamento básico e dar a melhor experiência e cookies de terceiros para obter dados estatísticos da tua navegação e publicitar produtos personalizados. Para mais informação podes ver a nossa <a href="/politica-cookies.html">Política de Cookies</a>, <a href="/aviso-legal.html">Aviso Legal</a> y <a href="/politica-privacidad.html">Política de Privacidade</a>, e a <a href="https://policies.google.com/privacy" target="_blanc">Política de Privacidade do Google</a>.',
      primary_btn: 'Aceitar todos',
      secondary_btn_1: 'Gerir cookies',
      secondary_btn_2: 'Recusar todos'
    },
    settings_modal: {
      title: 'Gerir cookies',
      save_settings_btn: 'Guardar configuração',
      accept_all_btn: 'Aceitar todos',
      reject_all_btn: 'Recusar todos',
      close_btn_label: 'Fechar',
      cookie_table_headers: {
        col1: 'Nome',
        col2: 'Domínio',
        col3: 'Tempo',
        col4: 'Descrição'
      },
      blocks_1: {
        title: 'Uso de cookies',
        description: 'Utilizamos cookies próprios para assegurar-nos do funcionamento básico e dar a melhor experiência e cookies de terceiros para obter dados estatísticos da tua navegação e publicitar produtos personalizados. Para mais informação podes ver a nossa <a href="/politica-cookies.html">Política de Cookies</a>, <a href="/aviso-legal.html">Aviso Legal</a> y <a href="/politica-privacidad.html">Política de PrivacidadE</a>, e a <a href="https://policies.google.com/privacy" target="_blanc">Política de Privacidade do Google</a>.'
      },
      blocks_2: {
        title: 'Cookies estritamente necessários',
        description: 'Estes cookies são essenciais para o correto funcionamento da web. Sem estes cookies a web não funcionaria bem.'
      },
      blocks_3: {
        title: 'Cookies funcionais',
        description: 'Estes cookies são importantes para o correto funcionamento da web. Sem estes cookies não podemos oferecer-te a melhor experiência.'
      },
      blocks_4: {
        title: 'Cookies de analítica e rendimento',
        description: 'Estes cookies permitem-nos medir de forma anónima o uso e rendimento da web.'
      },
      blocks_5: {
        title: 'Cookies de publicidade e marketing',
        description: 'Estes cookies permitem-nos publicitar produtos personalizados ao utilizador.'
      },
      cookies: {
        hash: {
          expiration: 'Sessão',
          description: 'Guarda a informação da página a partir da qual se realiza o processo de login e registar quando se usa o login com redes sociais (Google ou Facebook)'
        },
        auth_strategy: {
          expiration: '1 mês',
          description: 'Guarda a informação do sistema de login escolhido pelo utilizador (Local, Google ou Facebook)'
        },
        cc_runnea: {
          expiration: '1 ano',
          description: 'Guarda a informação das preferências de cookies do utilizador'
        },
        brander: {
          expiration: 'Entre 1 dia e 1 ano',
          description: 'Guarda a informação sobre a visualização de layers informativos para não voltar a mostrá-los'
        },
        ga: {
          expiration: '2 anos',
          description: 'Estes cookies são usados pelo Google Analytics para distinguir utilizadores únicos atribuíndo-lhes um número gerado aleatoriamente'
        },
        gid: {
          expiration: '2 anos',
          description: 'Estes cookies são usados pelo Google Analytics para gerar um identificador de utilizador único para fazer a contabilização de quantas vezes visita um local ou um utilizador, tal como a data da primeira e última vez que visitou a web.'
        },
        fbp: {
          expiration: '3 meses',
          description: 'Utilizado pelo Facebook para oferecer uma série de produtos publicitários, como ofertas em tempo real de anunciantes externos'
        },
        gads: {
          expiration: '13 meses',
          description: 'Associada ao serviço DoubleClick for Publishers da Google, tem o propósito de medir as interações com os anúncios para prevenir mostrá-los repetidamente'
        }
      }
    }
  },
  recommender: {
    seo: {
      title: 'Recomendador de sapatilhas: assistente de ajuda para escolher qual comprar | Shoe finder | RUNNEA',
      description: 'Guia para encontrar as sapatilhas de running, trail, trekking ou sneaker que melhor se ajustam a cada perfil, passo a passo.'
    },
    tabs: {
      start: {
        text: 'Olá, runneante! Estás à procura de umas sapatilhas novas? Ajudamos-te a encontrar as que melhor se adaptam a ti e ao teu orçamento em passos muito simples.',
        button: 'Começar',
        results: 'Ver resultados',
        autor: 'Gorka Cabañas',
        specialist: 'Especialista em sapatilhas de running'
      },
      purpose: {
        title: 'Em que superfície vais utilizar as sapatilhas?',
        text: 'Não é o mesmo correr no asfalto e na montanha. Tal como também não é o mesmo uma sapatilha para caminhar e outra para ter estilo. Diz-nos que tipo de sapatilhas estás à procura.',
        options: {
          running: 'Asfalto',
          trail: 'Trail'
        }
      },
      gender: {
        title: 'Que tipo de sapatilhas necessitas?',
        text: 'Na hora de procurar um modelo de sapatilhas temos de nos basear em dois opções principais.',
        options: {
          man: 'Homem',
          woman: 'Mulher'
        }
      },
      size: {
        title: 'Diz-nos o teu tamanho, por favor.',
        text: 'Conselho RUNNEA: A equipa de podólogos de RUNNEA recomenda escolher um calçado para fazer desporto que seja meio número acima do que utilizas habitualmente.',
        options: {
          default: 'Escolher tamanho'
        }
      },
      footprint: {
        title: 'Que tipo de pisada tens?',
        text: 'Se não sabes, recomendamos que faças uma análise da pisada. Irá ajudar-te a evitar lesões e correr de forma mais segura.',
        options: {
          pronator: 'Pronador',
          neutral: 'Neutro',
          supinator: 'Supinador',
          none: 'Não sei'
        }
      },
      constitution: {
        title: 'Consideras que a tua constituição é...',
        text: 'A nossa constituição física influi de maneira determinante na hora de escolher uma sapatilha ou outra. Quanto maior seja o nosso peso, maior será a necessidade de amortecimento que vamos necessitar.',
        options: {
          light: 'Leve',
          medium: 'Médio',
          strong: 'Forte',
          none: 'Não sei'
        }
      },
      cushioning: {
        title: 'Que tipo de amortecimento procuras?',
        text: 'Preferes um amortecimento mais firme, que te ajude a melhorar as tuas marcas, ou procuras comodidade e o máximo conforto sacrificando essa reactividade?',
        options: {
          soft: 'Rápida e reativa',
          neutral: 'Intermédia',
          high: 'Máximo amortecimento',
          none: 'Não sei'
        }
      },
      foot: {
        title: 'Podes indicar-nos a forma do teu pé?',
        text: 'Conhecer o teu tipo de pé ajuda-nos a selecionar umas sapatilhas que se adaptem melhor à tua forma de correr.',
        options: {
          normal: 'Normal',
          dig: 'Cavo',
          flat: 'Plano',
          none: 'Não sei'
        }
      },
      use: {
        title: 'Que uso vais dar às sapatilhas?',
        text: 'O ideal é ter umas sapatilhas para competir e outras para treinar, mas sempre tens a opção de escolher umas sapatilhas mistas, que podes utilizar tanto para treino como para competições.',
        options: {
          training: 'Treino',
          competition: 'Competição',
          mixed: 'Mista'
        }
      },
      distance: {
        title: 'Que distância vais treinar?',
        text: 'Aqui ninguém vai julgar o teu número de quilómetros, mas já que estamos nisto, gostas mais de distâncias curtas ou longas?',
        options: {
          short: 'Distâncias curtas',
          '10K': '10K',
          'half-maraton': 'Meia maratona',
          maraton: 'Maratona',
          'ultra-trail': 'Ultra trail'
        }
      },
      beat: {
        title: 'A que ritmo habitualmente corres?',
        text: 'És dos que não olha o relógio quando corre ou, pelo contrário, treinas todos os dias para melhorar as tuas marcas?',
        options: {
          high: 'Menos de 4:30 Min/Km',
          medium: 'Entre 4:30 e 5:30 Min/Km',
          low: 'Mais de 5:30 Min/Km',
          none: 'Não sei'
        }
      },
      brand: {
        title: 'Força! Já falta pouco 😊. Tens uma marca favorita?',
        text: 'Na hora de procurar um modelo de sapatilhas temos de nos basear em dois opções principais.',
        options: {
          default: 'Escolher marca',
          none: 'Surpreendam-me!'
        }
      },
      price: {
        title: 'Quanto tens pensado gastar?',
        text: 'O orçamento é importante mas em RUNNEA queremos ajudar-te a encontrar as tuas sapatilhas ideiaas ao melhor preço.',
        options: {
          low: 'Menos de 60€',
          medium: 'Menos de 100€',
          high: 'Mais de 100€',
          none: 'Sem orçamento'
        }
      },
      result: {
        texts: {
          loading: 'A carregar sapatilhas com todas as caraterísticas que nos deste...',
          contents: 'As sapatilhas que melhor se adaptam a ti são...',
          void: 'Não encontrámos sapatilhas com todas as caraterísticas que nos deste. Experimenta o nosso buscador de sapatilhas de running:',
          more_results: 'Podes ver mais resultados aqui'
        },
        product: {
          index: 'Ver características',
          prices: 'Ver preços'
        },
        buttons: {
          contents: 'Ver mais opções',
          void: 'Ver o resto das opções'
        }
      }
    }
  },
  runningMusic: {
    imgCalienta: 'https://static.runnea.com/images/202309/calienta.jpg?1',
    imgInfoIntencidad: 'https://static.runnea.com/images/202309/info-intensidad.png?1',
    imgIntencidad: 'https://static.runnea.com/images/202309/intensidad.png?1',
    imgPop: 'https://static.runnea.com/images/202309/pop.png?1',
    imgRock: 'https://static.runnea.com/images/202309/rock.png?1',
    imgSession01: 'https://static.runnea.com/images/202309/sesion-01.png?1',
    imgSession02: 'https://static.runnea.com/images/202309/sesion-02.png?1',
    imgSession03: 'https://static.runnea.com/images/202309/sesion-03.png?1',
    imgTechno: 'https://static.runnea.com/images/202309/techno.png?1',
    imgUrban: 'https://static.runnea.com/images/202309/urban.png?1'
  },
  recomend: {
    title: 'Recomendador de sapatilhas',
    subTitle: 'Resposta imediata',
    descriptions: 'Guia-te com passos simples para escolher a tua sapatilha'
  }
}
